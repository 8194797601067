import React, { useEffect, useState, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useSessionStorage } from 'react-use';
import styled from 'styled-components';

import { URL_REALTIME_SALES, URL_STEP1, URL_STEP3, URL_USP_SHIPPING } from 'domains/dashboard/bis/constants/const-urls';
import { ENUM_PAGE } from 'domains/dashboard/bis/constants/enums';
import useSubscription from 'hook/useSubscription';
import { fetchPage } from 'redux/actions';
import theme from 'styles/theme';

function SidebarHeaderToggleBtn() {
  const location = useLocation();
  const dispatch = useDispatch();
  const storeCode = useSelector((state) => state.common.selectedStoreCode);

  const [page, setPage] = useSessionStorage('page', ENUM_PAGE.BIS);
  const [selected, setSelected] = useState(page);

  const { hasSubscriptionAccess } = useSubscription();

  const handleToggle = useCallback(
    (value) => {
      setSelected(value);
      setPage(value);
    },
    [setPage],
  );

  const indexUrl = `/${URL_STEP1}/${URL_REALTIME_SALES}/${'ALL' && 'initstore'}`;

  useEffect(() => {
    if (location.pathname === indexUrl) {
      handleToggle(ENUM_PAGE.BIS);
    }
  }, [location, handleToggle, indexUrl]);

  useEffect(() => {
    dispatch(fetchPage(page));
  }, [page, dispatch]);

  return (
    hasSubscriptionAccess && (
      <ToggleButtonContainer>
        <Link to={`${URL_STEP1}/${URL_REALTIME_SALES}/${storeCode}`}>
          <ToggleButton active={selected === ENUM_PAGE.BIS} onClick={() => handleToggle(ENUM_PAGE.BIS)}>
            {ENUM_PAGE.BIS.toUpperCase()}
          </ToggleButton>
        </Link>
        <Link to={`${URL_STEP3}/${URL_USP_SHIPPING}/${storeCode}`}>
          <ToggleButton active={selected === ENUM_PAGE.SCMS} onClick={() => handleToggle(ENUM_PAGE.SCMS)}>
            {ENUM_PAGE.SCMS.toUpperCase()}
          </ToggleButton>
        </Link>
      </ToggleButtonContainer>
    )
  );
}

export default SidebarHeaderToggleBtn;

const ToggleButtonContainer = styled.div`
  display: flex;
  width: 248px;
  margin-top: 32px;
  padding: 2px;
  border: 1px solid ${theme.color.softGray};
  border-radius: 4px;
`;

const ToggleButton = styled.button`
  width: 121px;
  height: 32px;
  font-size: 13px;
  font-family: 'NanumSquareOTF';
  font-weight: bold;
  cursor: pointer;
  background-color: ${(props) => (props.active ? '#000' : '#fff')};
  color: ${(props) => (props.active ? '#Fff' : theme.color.darkGray)};
  border: none;
  border-radius: 4px;
  outline: none;
  transition: background-color 0.3s ease-in-out;
`;
