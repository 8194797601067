import React, { useState, useMemo, useCallback } from 'react';

import AlertContext from 'context/alert-context';

import Alert from '.';

export default function AlertDialog({ children }) {
  const [state, setState] = useState();

  const alert = useCallback(
    (description, buttonLabel) =>
      new Promise((resolve) => {
        setState({
          description: description ?? '',
          buttonLabel: buttonLabel ?? '확인',
          onClose: () => {
            setState(undefined);
            resolve(true);
            document.body.style.overflow = 'unset';
          },
        });
      }),
    [],
  );

  const contextValue = useMemo(() => ({ alert }), [alert]);

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      {state && <Alert description={state.description} onClose={state.onClose} buttonLabel={state.buttonLabel} />}
    </AlertContext.Provider>
  );
}
