import {
  ENUM_MENU_TYPE,
  URL_COFFEE_MACHINE_ICE_MAKER,
  URL_JUICE_DISPENSER,
  URL_MONTHLY_ANALYSIS,
  URL_OPERATE_INDICATOR,
  URL_OPERATE_REPORT,
  URL_OPERATE_STATUS,
  URL_PERIOD_SALES,
  URL_RAW_MATERIAL_STATUS_FORECAST,
  URL_REALTIME_SALES,
  URL_STEP1,
  URL_STEP2,
  URL_STEP3,
  URL_STORE,
  URL_USERS,
  URL_USP_SHIPPING,
} from 'domains/dashboard/bis/constants/const-urls';
import { ENUM_SELECT_STORE } from 'domains/dashboard/bis/constants/enums';

export const CFN_choiceStoreType = (pathname) => {
  const urls = `${pathname}`;

  if (urls.includes(`/${URL_STEP1}/${URL_REALTIME_SALES}`)) return ENUM_SELECT_STORE.STORES_PLUS_ALL;
  if (urls.includes(`/${URL_STEP1}/${URL_PERIOD_SALES}`)) return ENUM_SELECT_STORE.STORES_PLUS_ALL;
  if (urls.includes(`/${URL_STEP1}/${URL_MONTHLY_ANALYSIS}`)) return ENUM_SELECT_STORE.STORES_PLUS_ALL;
  if (urls.includes(`/${URL_STEP1}/${URL_OPERATE_STATUS}`)) return ENUM_SELECT_STORE.STORES_OPERATE;
  if (urls.includes(`/${URL_STEP1}/${URL_OPERATE_INDICATOR}`)) return ENUM_SELECT_STORE.STORES_PLUS_ALL;
  if (urls.includes(`/${URL_STEP1}/${URL_RAW_MATERIAL_STATUS_FORECAST}`)) return ENUM_SELECT_STORE.STORES_PLUS_ALL;
  if (urls.includes(`/${URL_STEP1}/${URL_COFFEE_MACHINE_ICE_MAKER}`)) return ENUM_SELECT_STORE.STORES_PLUS_ALL;
  if (urls.includes(`/${URL_STEP1}/${URL_JUICE_DISPENSER}`)) return ENUM_SELECT_STORE.STORES_PLUS_ALL;
  if (urls.includes(`/${URL_STEP1}/${URL_OPERATE_REPORT}`)) return ENUM_SELECT_STORE.STORES_PLUS_ALL;
  if (urls.includes(`/${URL_STEP2}/${URL_STORE}`)) return ENUM_SELECT_STORE.NONE;
  if (urls.includes(`/${URL_STEP2}/${URL_USERS}`)) return ENUM_SELECT_STORE.NONE;
  if (urls.includes(`/${URL_STEP3}/${URL_USP_SHIPPING}`)) return ENUM_SELECT_STORE.STORES_PLUS_ALL;

  return ENUM_SELECT_STORE.STORES_PLUS_ALL;
};

export const CFN_choiceMenuSelected = (pathname) => {
  const urls = `${pathname}`;

  if (urls.includes(`/${URL_STEP1}/${URL_REALTIME_SALES}`)) return ENUM_MENU_TYPE.REALTIME_SALES;
  if (urls.includes(`/${URL_STEP1}/${URL_PERIOD_SALES}`)) return ENUM_MENU_TYPE.PERIOD_SALES;
  if (urls.includes(`/${URL_STEP1}/${URL_MONTHLY_ANALYSIS}`)) return ENUM_MENU_TYPE.MONTHLY_ANALYSIS;
  if (urls.includes(`/${URL_STEP1}/${URL_OPERATE_STATUS}`)) return ENUM_MENU_TYPE.OPERATE_STATUS;
  if (urls.includes(`/${URL_STEP1}/${URL_OPERATE_INDICATOR}`)) return ENUM_MENU_TYPE.OPERATE_INDICATOR;
  if (urls.includes(`/${URL_STEP1}/${URL_RAW_MATERIAL_STATUS_FORECAST}`)) return ENUM_MENU_TYPE.COFFEE_MACHINE;
  if (urls.includes(`/${URL_STEP1}/${URL_COFFEE_MACHINE_ICE_MAKER}`)) return ENUM_MENU_TYPE.ICE_MAKER;
  if (urls.includes(`/${URL_STEP1}/${URL_JUICE_DISPENSER}`)) return ENUM_MENU_TYPE.JUICE_DISPENSER;
  if (urls.includes(`/${URL_STEP1}/${URL_OPERATE_REPORT}`)) return ENUM_MENU_TYPE.OPERATE_REPORT;
  if (urls.includes(`/${URL_STEP2}/${URL_STORE}`)) return ENUM_MENU_TYPE.STORE_MANAGE;
  if (urls.includes(`/${URL_STEP2}/${URL_USERS}`)) return ENUM_MENU_TYPE.USER_MANAGE;
  if (urls.includes(`/${URL_STEP3}/${URL_USP_SHIPPING}`)) return ENUM_MENU_TYPE.USP_SHIPPING;

  return ENUM_MENU_TYPE.REALTIME_SALES;
};
