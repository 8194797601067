import React from 'react';

import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default function Toast() {
  return <StyledToast autoClose={3000} />;
}

const StyledToast = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 0px;
    font-size: 13px;
    font-family: 'NanumSquareOTF';
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
    white-space: pre-line;
  }
`;
