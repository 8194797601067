import React, { useContext, useEffect } from 'react';

import IconButton from '@mui/material/IconButton';

import LayoutContext from './LayoutContext/LayoutContext';
import { SvgMenu } from './svg';

function SidebarToggleHandler({ children, ...restProps }) {
  const { isOpen, setOpen } = useContext(LayoutContext);

  useEffect(() => {
    setOpen(true); // 왼쪽 사이드 메뉴를 기본으로 열리게 하려면 주석을 풀 것.
  }, [setOpen]);

  return (
    <IconButton className="Cmt-toggle-menu" onClick={() => setOpen(!isOpen)} {...restProps} size="large">
      {children || <SvgMenu />}
    </IconButton>
  );
}

export default SidebarToggleHandler;
