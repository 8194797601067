import React, { useEffect } from 'react';

import { Button } from '@mui/material';
import styled from 'styled-components';

export default function Alert({ description, onClose, buttonLabel }) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  return (
    <ModalOverlay>
      <ModalContent>
        <Description>{description}</Description>
        <ButtonContainer>
          <StyledButton color="confirm" variant="contained" onClick={onClose}>
            {buttonLabel}
          </StyledButton>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
}

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

const ModalContent = styled.div`
  padding: 2.5rem;
  margin: 0;
  text-align: center;
  background-color: white;
  min-width: 24rem;
`;

const Description = styled.div`
  margin: 0;
  font-size: 1rem;
  white-space: pre-line;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

const StyledButton = styled(Button)`
  width: 100px;
  height: 40px;
`;
