import React, { useEffect } from 'react';

import { Slide, Snackbar, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { fetchError } from 'redux/actions';

import PageLoader from '../page-components/PageLoader';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

function ContentLoader() {
  const { error, loading, message } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchError(''));
    }, 3000);
  }, [dispatch, error, message]);

  return (
    <>
      {loading && <PageLoader />}
      <Snackbar
        open={Boolean(error)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={SlideTransition}>
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(message)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={SlideTransition}>
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ContentLoader;
