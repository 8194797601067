/**
 * URL 링크 STEP1 : bis
 */
export const URL_STEP1 = 'bis';

/**
 * URL 링크 STEP2 : manage
 */
export const URL_STEP2 = 'manage';

/**
 * URL 링크 STEP3 : scms
 */
export const URL_STEP3 = 'scms';

/**
 * 실시간 현황
 */
export const URL_REALTIME_SALES = 'realtime-sales';

/**
 * 실적 현황 - 일별 실적
 */
export const URL_PERIOD_SALES = 'period-sales';

/**
 * 실적 현황 - 월별 분석
 */
export const URL_MONTHLY_ANALYSIS = 'monthly-analysis';

/**
 * 운영 현황
 */
export const URL_OPERATE_STATUS = 'operate-status';

/**
 * 운영 지표
 */
export const URL_OPERATE_INDICATOR = 'operate-indicator';

/**
 * 장비 운영 - 원자재 현황 예측
 */

export const URL_RAW_MATERIAL_STATUS_FORECAST = 'raw-material-status-forecast';

/**
 * 장비 운영 - 커피머신&제빙기
 */

export const URL_COFFEE_MACHINE_ICE_MAKER = 'coffee-machine-ice-maker';

/**
 * 장비 운영 - 쥬스 디스펜서
 */

export const URL_JUICE_DISPENSER = 'juice-dispenser';

/**
 * 운영 보고서
 */
export const URL_OPERATE_REPORT = 'operate-report';

/**
 * USP 발송/관리
 */

export const URL_USP_SHIPPING = 'usp-shipping';

/**
 * 매장 관리
 */
export const URL_STORE = 'store';

/**
 * 물류 발주
 */
export const URL_LOGISTICS_ORDER = 'logistics-order';

/**
 * 사용자 관리
 */
export const URL_USERS = 'users';

export const ENUM_MENU_TYPE = {
  REALTIME_SALES: 0,
  PERIOD_SALES: 1,
  MONTHLY_ANALYSIS: 2,
  OPERATE_STATUS: 3,
  OPERATE_INDICATOR: 4,
  COFFEE_MACHINE: 5,
  ICE_MAKER: 6,
  JUICE_DISPENSER: 7,
  OPERATE_REPORT: 8,
  STORE_MANAGE: 9,
  USER_MANAGE: 10,
  USP_SHIPPING: 11,
};
