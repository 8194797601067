import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import SignIn from '@jumbo/components/common/auth-components/sign-in';
import { STR_INIT_STORE } from 'domains/dashboard/bis/constants/const-strings';
import { URL_REALTIME_SALES, URL_STEP1 } from 'domains/dashboard/bis/constants/const-urls';

const INDEX_URL = `/${URL_STEP1}/${URL_REALTIME_SALES}/${STR_INIT_STORE}`;

function Login() {
  const navigate = useNavigate();
  const authUser = useSelector(({ auth }) => auth.authUser);

  useEffect(() => {
    if (authUser) {
      navigate(INDEX_URL);
    } else {
      navigate('/signin');
    }
  }, [authUser, navigate]);

  return <SignIn />;
}

export default Login;
