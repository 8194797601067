import React from 'react';

import {
  SvgAssessment,
  SvgChart,
  SvgCoupon,
  SvgDashboard,
  SvgDesktop,
  SvgLogistics,
  SvgMembers,
  SvgStore,
} from 'assets/icons';
import {
  URL_COFFEE_MACHINE_ICE_MAKER,
  URL_JUICE_DISPENSER,
  URL_LOGISTICS_ORDER,
  URL_MONTHLY_ANALYSIS,
  URL_OPERATE_INDICATOR,
  URL_OPERATE_STATUS,
  URL_PERIOD_SALES,
  URL_RAW_MATERIAL_STATUS_FORECAST,
  URL_REALTIME_SALES,
  URL_STEP1,
  URL_STEP2,
  URL_STEP3,
  URL_STORE,
  URL_USERS,
  URL_USP_SHIPPING,
} from 'domains/dashboard/bis/constants/const-urls';
import { FormatMsg } from 'utils/format-msg';

// BIS
export const BisMenus = (store) => [
  // 메뉴 - 실시간 현황
  {
    name: FormatMsg('pages.RealtimeStatus'),
    icon: <SvgDashboard />,
    type: 'item',
    link: `/${URL_STEP1}/${URL_REALTIME_SALES}/${store}`,
  },

  // 메뉴 - 실적 현황
  {
    name: FormatMsg('pages.resultStatus'),
    icon: <SvgAssessment />,
    type: 'collapse',
    children: [
      {
        name: FormatMsg('pages.dailyPerformance'),
        type: 'item',
        link: `/${URL_STEP1}/${URL_PERIOD_SALES}/${store}`,
        font: 16,
      },
      {
        name: FormatMsg('pages.monthlyPerformance'),
        type: 'item',
        link: `/${URL_STEP1}/${URL_MONTHLY_ANALYSIS}/${store}`,
        font: 16,
      },
    ],
  },

  // 메뉴 - 운영 현황
  {
    name: FormatMsg('pages.operateStatus'),
    icon: <SvgDesktop />,
    type: 'item',
    link: `/${URL_STEP1}/${URL_OPERATE_STATUS}/${store}`,
  },

  // 메뉴 - 운영 지표
  {
    name: FormatMsg('pages.indicators'),
    icon: <SvgChart />,
    type: 'item',
    link: `/${URL_STEP1}/${URL_OPERATE_INDICATOR}/${store}`,
  },

  // 메뉴 - 장비 운영
  {
    name: FormatMsg('pages.equipmentOperation'),
    icon: <SvgChart />,
    type: 'collapse',
    children: [
      {
        name: FormatMsg('pages.rawMaterialStatusForecast'),
        type: 'item',
        link: `/${URL_STEP1}/${URL_RAW_MATERIAL_STATUS_FORECAST}/${store}`,
        font: 16,
      },
      {
        name: `${FormatMsg('pages.coffeeMachine')}`,
        type: 'item',
        link: `/${URL_STEP1}/${URL_COFFEE_MACHINE_ICE_MAKER}/${store}`,
        font: 16,
      },
      {
        name: FormatMsg('pages.juiceDispenser'),
        type: 'item',
        link: `/${URL_STEP1}/${URL_JUICE_DISPENSER}/${store}`,
        font: 16,
      },
    ],
  },

  // 메뉴 - 방문자 추적분석
  // {
  //   name: formatMessage({ id: 'pages.visitTrace' }),
  //   icon: <SvgGpsFixedGray />,
  //   type: 'item',
  //   link: `/${URL_STEP1}/${URL_VISIT_TRACE}/${store}`,
  // },
];

// * 메뉴 - 운영보고서
// export const menuOperateReport = store => {
//   return {
//     name: intl.formatMessage({ id: 'pages.operateReport' }),
//     // icon: <SvgPasteGray />,
//     type: 'item',
//     link: `/${URL_STEP1}/${URL_OPERATE_REPORT}/${store}`,
//   };
// };

// S-CMS
export const ScmsMenus = (store) => [
  // 메뉴 - USP 발송/관리
  {
    name: FormatMsg('pages.uspShipping'),
    icon: <SvgCoupon />,
    type: 'item',
    link: `/${URL_STEP3}/${URL_USP_SHIPPING}/${store}`,
  },
  {
    name: '물류 발주',
    icon: <SvgLogistics />,
    type: 'item',
    link: `/${URL_STEP3}/${URL_LOGISTICS_ORDER}/${store}`,
  },
];

// 메뉴 - 매장 관리
export const MenuManageStore = () => ({
  name: FormatMsg('pages.manageStore'),
  icon: <SvgStore />,
  type: 'item',
  link: `/${URL_STEP2}/${URL_STORE}/ALL`,
});

// 메뉴 - 사용자 관리
export const MenuManageUsers = () => ({
  name: FormatMsg('pages.manageUser'),
  icon: <SvgMembers />,
  type: 'item',
  link: `/${URL_STEP2}/${URL_USERS}/ALL`,
});
