import {
  GET_BEAT_STORES,
  ADD_BEAT_STORE,
  SET_BEAT_STORE_DETAILS,
  EDIT_BEAT_STORE,
  DELETE_BEAT_STORE,
} from '@jumbo/constants/action-types';

const INIT_STATE = {
  beatstores: [],
  currentBeatStore: null,
};

const beatStoreReducer = (state = INIT_STATE, action = '') => {
  switch (action.type) {
    case GET_BEAT_STORES: {
      return {
        ...state,
        beatstores: action.payload,
      };
    }
    case SET_BEAT_STORE_DETAILS: {
      return {
        ...state,
        currentBeatStore: action.payload,
      };
    }
    case ADD_BEAT_STORE: {
      return {
        ...state,
        beatstores: [action.payload, ...state.beatstores],
      };
    }
    case EDIT_BEAT_STORE: {
      return {
        ...state,
        beatstores: state.beatstores.map((store) => (store.seqno === action.payload.seqno ? action.payload : store)),
      };
    }
    case DELETE_BEAT_STORE: {
      return {
        ...state,
        beatstores: state.beatstores.filter((store) => store.seqno !== action.payload),
      };
    }
    default:
      return state;
  }
};

export default beatStoreReducer;
