import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router';

import ChangePasswordPage from 'domains/auth/pages/change-password';
import Login from 'domains/auth/pages/login';

export default function PrivateRouter() {
  const navigate = useNavigate();
  const { token } = useParams();
  const authUser = useSelector(({ auth }) => auth.authUser);

  useEffect(() => {
    if (!authUser) {
      token ? navigate(`/change/${token}`) : navigate('/signin', { replace: true });
    }
  }, [authUser, navigate, token]);

  if (!authUser) {
    return token ? <ChangePasswordPage /> : <Login />;
  }
  return token ? <ChangePasswordPage /> : <Outlet />;
}
