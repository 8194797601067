import React, { useContext, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Badge, InputAdornment, TextField } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { isNull } from 'lodash';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';

import AlertContext from 'context/alert-context';
import { ENUM_SELECT_STORE } from 'domains/dashboard/bis/constants/enums';
import useClasses from 'hook/useClasses';
import useSubscription from 'hook/useSubscription';
import theme from 'styles/theme';
import { CFN_isAdmin, isPremium } from 'utils/comm-function';

const MAX_LENGTH = 100;

function ModuleAutocomplete({ value, setValue, options, storeType }) {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();
  const location = useLocation();
  const roleName = useSelector((state) => state.auth.authUser?.roleName);
  const styles = useClasses(autocompleteStyles);
  const position = location.pathname.lastIndexOf('/');
  const url = location.pathname.slice(0, position + 1);

  const { alert } = useContext(AlertContext);
  const { isOnSubscriptionPage } = useSubscription();

  const [inputValue, setInputValue] = useState('');
  const [storeName, setStoreName] = useState('');

  const handleOnChange = async (e, newValue) => {
    setStoreName(newValue?.name);
    if (CFN_isAdmin(roleName)) {
      setValue(newValue);
      navigate(`${url}${newValue.code}`);
      return null;
    }

    if (newValue.code !== 'ALL') {
      if (!isPremium(newValue) && isOnSubscriptionPage) {
        return alert(formatMessage({ id: 'error.message.subscription' }, { name: storeName }));
      }
      setValue(newValue);
      navigate(`${url}${newValue.code}`);
      return null;
    }

    setValue(newValue);
    navigate(`${url}${newValue.code}`);
    return null;
  };

  const getTextColor = (part, selectCode) => {
    if (part?.highlight) return theme.color.red;
    if (selectCode) return '#fff';
    return '#000';
  };

  return (
    <Autocomplete
      id="select-beatstore"
      fullWidth
      disableClearable
      value={value}
      options={options}
      onChange={handleOnChange}
      inputValue={inputValue === value?.name ? '' : inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={(option) => option?.name || ''}
      getOptionDisabled={(option) => {
        if (storeType === ENUM_SELECT_STORE.STORES_LIDAR) {
          return isNull(option?.floorId);
        }
        return false;
      }}
      sx={{ width: 500 }}
      classes={{ paper: styles.paper, option: styles.option }}
      loading
      loadingText={formatMessage({
        id: 'manage.users.warning.notFoundStore',
      })}
      isOptionEqualToValue={(option, value) => option?.name === value?.name}
      renderTags={() => {}} // chips를 안 보이게 할 때
      renderOption={(props, option, { inputValue }) => {
        const removeBlankInputValue = inputValue.replace(/(\s*)/g, ''); // 문자열 모든 공백 제거
        const matches = match(option?.name, removeBlankInputValue);
        const parts = parse(option?.name, matches);
        const selectCode = option?.code === value?.code;

        return (
          <li {...props}>
            <StyledBadge anchorOrigin={{ vertical: 'top', horizontal: 'left' }} variant="dot" invisible={!option.floorId}>
              {parts?.map((part) => {
                const fontWeight = part?.highlight ? 700 : 400;
                const textColor = getTextColor(part, selectCode);
                return (
                  <span key={part.text} style={{ fontWeight, color: textColor }}>
                    {part.text}
                  </span>
                );
              })}
            </StyledBadge>
          </li>
        );
      }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          hiddenLabel
          placeholder={formatMessage({
            id: 'autocomplete.select.store.placeholder',
          })}
          variant="filled"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            inputProps: { ...params.inputProps, maxLength: MAX_LENGTH },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      ListboxProps={{ style: { maxHeight: 375 } }}
    />
  );
}

export default ModuleAutocomplete;

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.color.blue,
    color: theme.color.blue,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const StyledTextField = styled(TextField)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  height: 40px;
  background-color: #fff;
  border-radius: 4px;
  & .MuiFilledInput-root {
    background-color: transparent;
  }
`;

const autocompleteStyles = {
  paper: {
    border: '1px solid #000',
  },

  option: {
    '&:hover': {
      backgroundColor: '#EFF7FD !important',
      color: '#000 !important',
    },

    '&[aria-selected="true"]': {
      backgroundColor: '#23275f !important',
      color: '#fff !important',
    },
  },
};
