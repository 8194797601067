import React from 'react';

import { Box } from '@mui/material';

import { ENUM_SELECT_STORE } from 'domains/dashboard/bis/constants/enums';

import ModuleAutocomplete from './module-autocomplete';

export default function Output({ storeType, filteredStore, value, setValue, storeList }) {
  if (storeType === ENUM_SELECT_STORE.NONE) {
    return null;
  }

  if (filteredStore.length > 1) {
    return (
      <ModuleAutocomplete
        value={value}
        setValue={setValue}
        options={filteredStore}
        storeType={storeType}
        storeList={storeList}
      />
    );
  }
  if (filteredStore.length === 1) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
        <span style={{ fontSize: 22, color: '#fff', fontWeight: 'bold' }}>{filteredStore[0]?.name}</span>
      </Box>
    );
  }
  return null;
}
