import React, { useCallback, useMemo, useState } from 'react';

import ConfirmContext from 'context/confirm-context';

import Confirm from '.';

export default function ConfirmDialog({ children }) {
  const [state, setState] = useState();

  const confirm = useCallback(
    (description, buttonLabel) =>
      new Promise((resolve) => {
        setState({
          description: description ?? '',
          buttonLabel: buttonLabel ?? '확인',
          onConfirm: () => {
            setState(undefined);
            resolve(true);
            document.body.style.overflow = 'unset';
          },
          onCancel: () => {
            setState(undefined);
            resolve(false);
            document.body.style.overflow = 'unset';
          },
        });
      }),
    [],
  );

  const contextValue = useMemo(() => ({ confirm }), [confirm]);

  return (
    <ConfirmContext.Provider value={contextValue}>
      {children}
      {state && (
        <Confirm
          description={state.description}
          buttonLabel={state.buttonLabel}
          onConfirm={state.onConfirm}
          onCancel={state.onCancel}
        />
      )}
    </ConfirmContext.Provider>
  );
}
