import React, { useCallback, useEffect, useState } from 'react';

import { Button, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { passwordPattern } from 'domains/auth/constants/regex';
import PersonalInfo from 'domains/auth/pages/personal-info';
import Terms from 'domains/auth/terms';
import { fetchError, fetchStart } from 'redux/actions';
import { instance } from 'services/http';

import Auth from '.';
import ContentLoader from '../../content-loader';

// 비밀번호 등록하기
const MySwal = withReactContent(Swal);

const ENUM_STATUS = {
  unknown: 0,
  init: 1,
  change: 2,
};

function ChangePassword() {
  const { token } = useParams();
  const { formatMessage } = useIntl();

  const [status, setStatus] = useState(ENUM_STATUS.unknown);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  // 유효성 검사
  const [isPassword, setIsPassword] = useState(false);
  const [isPasswordConfirm, setIsPasswordConfirm] = useState(false);
  const [isExpire, setIsExpire] = useState(false);
  const [isSuccessRegist, setIsSuccessRegist] = useState(false);
  const [isShowForm, setIsShowForm] = useState(false);
  const [isFocusPassword, setIsFocusPassword] = useState(false);
  const [isFocusPasswordConfirm, setIsFocusPasswordConfirm] = useState(false);

  // 오류메세지
  const [errorMessagePassword, setErrorMessagePassword] = useState('');
  const [errorMessagePasswordConfirm, setErrorMessagePasswordConfirm] = useState('');
  const [errorMessagrExpire, setErrorMessageExpire] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [openPersonalInfoDialog, setOpenPersonalDialog] = useState(false);

  const handleCloseTermsDialog = () => {
    setOpenTermsDialog(false);
  };

  const handleClosePersonalInfoDialog = () => {
    setOpenPersonalDialog(false);
  };

  const sweetAlertsSuccess = useCallback(
    (title, text) => {
      if (isSuccessRegist) {
        MySwal.fire({
          icon: 'success',
          title,
          text,
          confirmButtonText: `${formatMessage({ id: 'change.password.goToHome' })}`,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/');
          }
        });
      }
    },
    [isSuccessRegist, navigate],
  );

  const sweetAlertsErrorCloseWindow = (title, text) => {
    MySwal.fire({
      icon: 'error',
      title,
      text,
      confirmButtonText: `${formatMessage({ id: 'change.password.closeWindow' })}`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.close();

        // 안닫긴 다면. 홈으로 이동
        navigate('/');
      }
    });
  };

  const getCheckTokenValid = (token) => (dispatch) => {
    dispatch(fetchStart());
    instance
      .get(`/auth/pswd/url-check?token=${token}`, {})
      .then((data) => {
        if (data.status === 200) {
          setIsExpire(false);
          setIsShowForm(true);
          setErrorMessageExpire('');
        } else {
          dispatch(fetchError(`code : ${data.status} There was something issue in responding server.`));
          setIsExpire(true);
          setErrorMessageExpire(data.message);
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.response.data.message));
        setIsExpire(true);
        setErrorMessageExpire(error.response.data.message);
      });
  };

  const postResetPassword = (token, params) => (dispatch) => {
    dispatch(fetchStart());
    instance
      .post(`/auth/pswd/reset/${token}`, params)
      .then((data) => {
        if (data.status === 200) {
          setIsSuccessRegist(true);
        } else {
          dispatch(fetchError(`code : ${data.status} There was something issue in responding server.`));
          setIsSuccessRegist(false);
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.response.data.message));
        setIsSuccessRegist(false);
      });
  };

  const enableRegistButton = () => {
    // 초기 등록일 경우
    if (status === ENUM_STATUS.init) {
      if (isNotEmptyString(password) && isPassword && isNotEmptyString(passwordConfirm) && isPasswordConfirm) {
        return false;
      }

      return true;
    }

    // 변경 하기 일경우
    if (isNotEmptyString(password) && isPassword && isNotEmptyString(passwordConfirm) && isPasswordConfirm) {
      return false;
    }

    return true;
  };

  const onSubmit = () => {
    if (!isPassword) {
      setErrorMessagePassword(formatMessage({ id: 'change.password.newPassword.error' }));
      return;
    }

    if (!isPasswordConfirm) {
      setErrorMessagePasswordConfirm(formatMessage({ id: 'change.password.confirmPassword' }));
      return;
    }

    onRegistPassword();
  };

  const onRegistPassword = () => {
    const params = {
      password,
      passwordConfirm,
    };

    dispatch(postResetPassword(token, params));
  };

  // const onClose = () => {
  //   console.log('### 창닫기');
  //   window.close();

  //   //만약 창이 안닫힌다면 홈으로 이동.
  //   navigate('/');
  // };

  // 새 비밀번호
  const onChangePassword = (event) => {
    const passwordCurrent = event.target.value;
    setPassword(passwordCurrent);

    // const validCheck = debounce(() => {
    // const hasError = !password || !passwordPattern03.test(passwordCurrent);

    if (!password) {
      setErrorMessagePassword(formatMessage({ id: 'change.password.newPasswordPlease' }));
      setIsPassword(false);
      setIsFocusPassword(true);
    } else if (!passwordPattern.test(passwordCurrent)) {
      setErrorMessagePassword(formatMessage({ id: 'change.password.newPassword.error' }));
      setIsPassword(false);
      setIsFocusPassword(false);
    } else {
      setErrorMessagePassword('');
      setIsPassword(true);
      setIsFocusPassword(false);
    }
    // }, 200);
    // validCheck();
  };

  // 새 비밀번호 확인
  const onChangePasswordConfirm = (event) => {
    const passwordCurrentConfirm = event.target.value;
    setPasswordConfirm(passwordCurrentConfirm);

    // const validCheck = debounce(() => {
    if (password === passwordCurrentConfirm) {
      setErrorMessagePasswordConfirm('');
      setIsPasswordConfirm(true);
      setIsFocusPasswordConfirm(false);
    } else {
      setErrorMessagePasswordConfirm(formatMessage({ id: 'change.password.confirmPassword.error' }));
      setIsPasswordConfirm(false);
      setIsFocusPasswordConfirm(true);
    }
    // }, 200);
    // validCheck();
  };

  const isNotEmptyString = (value) => value !== '';

  useEffect(() => {
    dispatch(getCheckTokenValid(token));

    if (location.pathname === `/init/${token}`) {
      setStatus(ENUM_STATUS.init);
    } else if (location.pathname === `/change/${token}`) {
      setStatus(ENUM_STATUS.change);
    } else {
      setStatus(ENUM_STATUS.unknown);
    }
  }, [token, dispatch, location.pathname]);

  useEffect(() => {
    sweetAlertsSuccess(
      formatMessage({ id: 'change.password.successRegistTitle' }),
      formatMessage({ id: 'change.password.successRegistMessage' }),
    );
  }, [sweetAlertsSuccess]);

  return isExpire ? (
    <div>{sweetAlertsErrorCloseWindow(formatMessage({ id: 'change.password.expireTitle' }), errorMessagrExpire)}</div>
  ) : (
    <Auth>
      {isShowForm ? (
        isSuccessRegist ? null : (
          <div>
            <Auth.Title>{formatMessage({ id: 'change.password.title' })}</Auth.Title>
            <form>
              <TextField
                inputProps={{
                  style: { fontFamily: 'sans-serif' },
                }}
                type="password"
                label={formatMessage({ id: 'change.password.newPassword' })}
                fullWidth
                onFocus={(event) => onChangePassword(event)}
                onChange={(event) => onChangePassword(event)}
                onKeyUp={(event) => onChangePassword(event)}
                margin="normal"
                variant="outlined"
                error={isFocusPassword || (isNotEmptyString(password) && !isPassword)}
                helperText={errorMessagePassword}
              />
              <TextField
                inputProps={{
                  style: { fontFamily: 'sans-serif' },
                }}
                style={{ margin: '6px 0px 0px' }}
                type="password"
                label={formatMessage({ id: 'change.password.confirmPassword' })}
                fullWidth
                onFocus={(event) => onChangePasswordConfirm(event)}
                onChange={(event) => onChangePasswordConfirm(event)}
                onKeyUp={(event) => onChangePasswordConfirm(event)}
                margin="normal"
                variant="outlined"
                error={isFocusPasswordConfirm || (isNotEmptyString(passwordConfirm) && !isPasswordConfirm)}
                helperText={errorMessagePasswordConfirm}
              />
              {status === ENUM_STATUS.init && (
                <div>
                  {openTermsDialog && <Terms open={openTermsDialog} onCloseDialog={handleCloseTermsDialog} />}
                  {openPersonalInfoDialog && (
                    <PersonalInfo open={openPersonalInfoDialog} onCloseDialog={handleClosePersonalInfoDialog} />
                  )}
                </div>
              )}
              <StyledSubmitBtn onClick={onSubmit} variant="contained" color="primary" disabled={enableRegistButton()}>
                {formatMessage({ id: 'change.password.doRegister' })}
              </StyledSubmitBtn>
            </form>
            <ContentLoader />
          </div>
        )
      ) : null}

      {}
    </Auth>
  );
}

export default ChangePassword;

const StyledSubmitBtn = styled(Button)`
  margin: 20px 0px;
  width: 100%;
  height: 56px;
  font-size: 16px;
  box-shadow: none;

  &.Mui-selected,
  &.Mui-selected:hover,
  &:hover {
    box-shadow: none;
  }
`;
