import React, { useImperativeHandle } from 'react';

import { Box } from '@mui/material';
import clsx from 'clsx';

import useClasses from 'hook/useClasses';

const styles = (theme) => ({
  appMainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    // padding: '30px 15px', 탑 패딩 너무 커서 수정
    padding: '0 15px 30px 15px',
    [theme.breakpoints.up('md')]: {
      padding: '0px 48px 32px 48px',
    },
    [theme.breakpoints.up('lg')]: {},
    [theme.breakpoints.up('xl')]: {},
    [theme.breakpoints.down('lg')]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
});

const CmtContent = React.forwardRef((props, ref) => {
  const { children } = props;

  useImperativeHandle(ref, () => ({}));

  const contentRef = React.createRef();
  const classes = useClasses(styles);

  return (
    <Box ref={contentRef} className={clsx(classes.appMainContent, 'Cmt-appMainContent')} bgcolor="background.main">
      {children}
    </Box>
  );
});

export default CmtContent;
CmtContent.defaultProps = {
  name: 'LayoutContent',
};
