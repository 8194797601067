import React, { useImperativeHandle } from 'react';

import AppBar from '@mui/material/AppBar';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useClasses from 'hook/useClasses';

const styles = (theme) => ({
  appHeader: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'all 0.3s ease',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none', // v0.3 디자인팀 요청에 따른 그림자 삭제 (2022.08.16)
  },
});

const CmtHeader = React.forwardRef((props, ref) => {
  const { type, fullHeader, className, children } = props;

  useImperativeHandle(ref, () => ({
    headerType: type,
    fullHeader,
  }));

  const contentRef = React.createRef();
  const classes = useClasses(styles);

  return (
    <AppBar position="static" ref={contentRef} className={clsx(classes.appHeader, className, 'Cmt-header')}>
      {children}
    </AppBar>
  );
});

export default CmtHeader;
CmtHeader.defaultProps = {
  type: 'fixed',
  name: 'LayoutHeader',
  fullHeader: false,
};
CmtHeader.propTypes = {
  type: PropTypes.oneOf(['fixed', 'static']),
  fullHeader: PropTypes.bool,
};
