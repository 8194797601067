import React from 'react';

import PropTypes from 'prop-types';

import LayoutContextProvider from './LayoutContext';
import MainContainer from './MainContainer';

function CmtVerticalLayout({
  miniSidebarWidth = 80,
  sidebarWidth = 304,
  drawerBreakPoint = 'sm',
  actionSidebarWidth = 72,
  ...restProps
}) {
  return (
    <LayoutContextProvider>
      <MainContainer
        miniSidebarWidth={miniSidebarWidth}
        sidebarWidth={sidebarWidth}
        actionSidebarWidth={actionSidebarWidth}
        drawerBreakPoint={drawerBreakPoint}
        {...restProps}
      />
    </LayoutContextProvider>
  );
}

CmtVerticalLayout.propTypes = {
  miniSidebarWidth: PropTypes.number,
  sidebarWidth: PropTypes.number,
  drawerBreakPoint: PropTypes.oneOf(['xs', 'sm', 'md']),
  actionSidebarWidth: PropTypes.number,
};

export default CmtVerticalLayout;
