import React, { useContext, useEffect } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import PrivateRoute from 'private-router';

import AppLocale from '../../../i18n';
import AppLayout from '../app-layout';
import AppContext from '../context-provider/AppContextProvider/AppContext';

import 'dayjs/locale/ko';

export default function AppWrapper({ children }) {
  const { direction } = useContext(AppContext);

  useEffect(() => {
    if (direction === 'rtl') {
      document.body.setAttribute('dir', 'rtl');
    } else {
      document.body.setAttribute('dir', 'ltr');
    }
  }, [direction]);

  const locale = useSelector((state) => state.common.locale);
  const currentAppLocale = AppLocale[locale];

  dayjs.locale(locale);

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <CssBaseline />
        <AppLayout>{children ?? <PrivateRoute />}</AppLayout>
      </LocalizationProvider>
    </IntlProvider>
  );
}
