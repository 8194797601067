import React from 'react';

import { Box } from '@mui/material';

import useClasses from 'hook/useClasses';

function PageLoader() {
  const classes = useClasses(styles);

  return <Box className={classes.circularProgressRoot} />;
}

export default PageLoader;

const styles = (theme) => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 999999,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
