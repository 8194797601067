export const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  white: '#ffffff',
  black: '#222222',
  gray: {
    50: '#f7f7f7',
    100: '#ededed',
    200: '#d9d9d9', // UI 표준 색상
    300: '#cccccc', // UI 표준 색상
    400: '#adadad',
    500: '#999999',
    600: '#888888', // UI 표준 색상
    700: '#7b7b7b',
    800: '#666666', // UI 표준 색상
    900: '#545454',
    950: '#363636',
  },
  red: {
    50: '#fdf6f6', // UI 표준 색상
    100: '#ffe1e1',
    200: '#ffc7c7',
    300: '#ffa0a0',
    400: '#ff5757', // UI 표준 색상
    500: '#f83b3b',
    600: '#e51d1d',
    700: '#c11414',
    800: '#a01414',
    900: '#841818',
    950: '#480707',
  },
  orange: {
    50: '#fff6ed',
    100: '#ffebd4',
    200: '#ffd3a9',
    300: '#ffb372',
    400: '#fe8839',
    500: '#fd6f1f', // UI 표준 색상
    600: '#ee4c08',
    700: '#c53709',
    800: '#9c2c10',
    900: '#7e2710',
    950: '#441006',
  },
  amber: {
    50: '#fff9eb',
    100: '#ffecc6',
    200: '#fed989',
    300: '#febe4b',
    400: '#fda41f',
    500: '#f78209',
    600: '#db5d04',
    700: '#b63e07',
    800: '#93300d',
    900: '#79280e',
    950: '#461202',
  },
  green: {
    50: '#f0fdf9',
    100: '#ccfbed',
    200: '#98f7dc',
    300: '#5debc9',
    400: '#2cd5b2',
    500: '#14c6a4', // UI 표준 색상
    600: '#0c957e',
    700: '#0e7766',
    800: '#105f54',
    900: '#134e45',
    950: '#042f2b',
  },
  cyan: {
    50: '#eefbfd',
    100: '#d4f4f9',
    200: '#aee9f3',
    300: '#76d6ea',
    400: '#38bcd9', // UI 표준 색상
    500: '#1b9fbf',
    600: '#1a80a0',
    700: '#1b6783',
    800: '#1f556b',
    900: '#1e475b',
    950: '#0e2e3e',
  },
  sky: {
    50: '#eaf2fa', // UI 표준 색상
    100: '#e2ecf7',
    200: '#cbdef2',
    300: '#a7cae9',
    400: '#7eaedc',
    500: '#5f92d2',
    600: '#4b79c5',
    700: '#4166b4',
    800: '#3a5593',
    900: '#334875',
    950: '#232d48',
  },
  blue: {
    50: '#f2f8fe', // UI 표준 색상
    100: '#dbe9fe',
    200: '#c0dafd',
    300: '#94c3fc',
    400: '#62a3f8',
    500: '#3c7ff4', // UI 표준 색상
    600: '#2761e9',
    700: '#1f4cd6',
    800: '#203fad',
    900: '#1f3989',
    950: '#182453',
  },
  violet: {
    50: '#f7f4fe',
    100: '#efebfc',
    200: '#e2dafa',
    300: '#ccbcf6',
    400: '#ad8eee', // UI 표준 색상
    500: '#9a6be7',
    600: '#8b4bdc',
    700: '#7b39c8',
    800: '#6630a7',
    900: '#562989',
    950: '#35185d',
  },
  danger: '#cd0000',
};
