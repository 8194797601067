import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import {
  URL_LOGISTICS_ORDER,
  URL_RAW_MATERIAL_STATUS_FORECAST,
  URL_USP_SHIPPING,
} from 'domains/dashboard/bis/constants/const-urls';

const SUBSCRIPTION_PAGES = [URL_RAW_MATERIAL_STATUS_FORECAST, URL_USP_SHIPPING, URL_LOGISTICS_ORDER];

export default function useSubscription() {
  const location = useLocation();
  const storeCode = useSelector((state) => state.common.selectedStoreCode);
  const storeList = useSelector((state) => (state.auth.authUser ? state.auth.authUser.storeList : []));
  const roleName = useSelector((state) => state.auth.authUser?.roleName);

  const selectedStoreSubscription = storeList.find((store) => store.code === storeCode)?.subscription;
  const isOnSubscriptionPage = SUBSCRIPTION_PAGES.some((path) => location.pathname.includes(path));
  const product = selectedStoreSubscription?.product;

  const [hasSubscriptionAccess, setHasSubscriptionAccess] = useState(true);

  useEffect(() => {
    if (roleName === 'MANAGER') {
      if (storeCode !== 'ALL') {
        if (selectedStoreSubscription) {
          const hasSubscriptionService = product !== 'BASIC';
          setHasSubscriptionAccess(hasSubscriptionService);
        }
      } else {
        setHasSubscriptionAccess(true);
      }
    }
  }, [storeCode, roleName, selectedStoreSubscription, product]);

  return { hasSubscriptionAccess, isOnSubscriptionPage, product };
}
