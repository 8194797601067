import React, { useCallback, useEffect, useState } from 'react';

import { isNull } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { apiStoreMonitoringList } from 'domains/dashboard/bis/services/indicator';
import { storeSelect, floorIdSelect, storeNameSelect, storeLidarTokenSelect } from 'redux/actions';
import { CFN_isInitstore } from 'utils/comm-function';
import { CFN_choiceStoreType } from 'utils/comm-url-checker';
import { getCookieLidar } from 'utils/get-cookie';

import Output from './output';

function HeaderStoreList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const storeList = useSelector((state) => (state.auth.authUser ? state.auth.authUser.storeList : []));
  const selectedLidarToken = useSelector(({ common }) => common.selectedLidarToken);
  const findPosition = location.pathname.lastIndexOf('/');
  const paramsStorecode = location.pathname.substring(findPosition + 1);
  const storeType = CFN_choiceStoreType(location.pathname);

  const [store, setStore] = useState([]);
  const [filteredStore, setFilteredStore] = useState([]);

  const [value, setValue] = useState(
    filteredStore[0] || {
      code: 'ALL',
      name: '전체',
    },
  );

  const normalDispatch = useCallback(
    (storecode, stores) => {
      if (isNull(storecode) || storecode === 'null' || CFN_isInitstore(storecode)) {
        let code = null;
        let floor = null;
        let name = null;
        if (stores.length > 0) {
          code = stores[0]?.code;
          floor = stores[0]?.floorId;
          name = stores[0]?.name;
        }

        dispatch(storeSelect(code));
        dispatch(floorIdSelect(floor));
        dispatch(storeNameSelect(name));
      } else {
        const clickedoption = stores.find((item) => item.code === storecode);
        if (clickedoption) {
          dispatch(storeSelect(clickedoption?.code));
          dispatch(floorIdSelect(clickedoption?.floorId));
          dispatch(storeNameSelect(clickedoption?.name));
        }
      }
    },
    [dispatch],
  );

  const apiMonitoringList = useCallback(
    (storecode) => {
      apiStoreMonitoringList()().then((res) => {
        setFilteredStore(res);
        normalDispatch(storecode, res);
      });
    },
    [normalDispatch],
  );

  useEffect(() => {
    if (filteredStore.length !== 0) {
      CFN_isInitstore(paramsStorecode)
        ? setValue(filteredStore[0])
        : setValue(filteredStore.find((store) => store?.code === paramsStorecode));
    }
  }, [filteredStore, paramsStorecode]);

  useEffect(() => {
    const filteredStoreList = () => {
      if (Object.keys(storeList).length <= 2) {
        const filtered = storeList.filter((store) => store.code !== 'ALL');
        return setStore(filtered);
      }
      return setStore(storeList);
    };

    filteredStoreList();
  }, [storeList]);

  useEffect(() => {
    // lidar token state save , 라이다 토큰이 다르면 state 업데이트 합니다.
    const lidarToken = getCookieLidar();
    if (lidarToken !== selectedLidarToken) {
      dispatch(storeLidarTokenSelect(lidarToken));
    }
  }, [dispatch, selectedLidarToken]);

  useEffect(() => {
    const loadStoreList = async () => {
      if (store.length < 1) return;

      setFilteredStore(store);
      normalDispatch(paramsStorecode, store);
    };

    loadStoreList();
  }, [storeType, storeList, store, paramsStorecode, normalDispatch, dispatch, apiMonitoringList]);

  useEffect(() => {
    const clickedOption = filteredStore.find((store) => store?.code === value?.code);
    if (clickedOption) {
      dispatch(storeSelect(clickedOption?.code));
      dispatch(floorIdSelect(clickedOption?.floorId));
      dispatch(storeNameSelect(clickedOption?.name));
    }
  }, [value, filteredStore, dispatch]);

  return <Output storeType={storeType} filteredStore={filteredStore} value={value} setValue={setValue} />;
}

export default React.memo(HeaderStoreList);
