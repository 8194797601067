import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { DialogActions, Grid, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { NotificationContainer } from 'react-notifications';

import useClasses from 'hook/useClasses';
import 'react-notifications/lib/notifications.css';

import { agree_terms } from './pages/agree/agree-terms';

function Terms({ open, onCloseDialog }) {
  const classes = useClasses(styles);
  const { formatMessage } = useIntl();

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} fullWidth maxWidth="md">
      <NotificationContainer />
      <DialogTitle className={classes.dialogTitleRoot}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          {formatMessage({ id: 'terms.agree' })}
          <IconButton aria-label="close" onClick={onCloseDialog} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <div dangerouslySetInnerHTML={{ __html: agree_terms }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>{formatMessage({ id: 'button.close' })}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default Terms;

Terms.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};

const styles = (theme) => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
});
