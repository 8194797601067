import { combineReducers } from 'redux';

import { routerReducer } from 'redux/store/history';

import Auth from './Auth';
import BeatStores from './BeatStore';
import Common from './Common';
import RealTime from './Realtime';
import Users from './Users';

const reducers = {
  router: routerReducer,
  common: Common,
  auth: Auth,
  usersReducer: Users,
  beatStoreReducer: BeatStores,
  realtime: RealTime,
};

export default combineReducers(reducers);
