/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

import dayjs from 'dayjs';
import { isNull } from 'lodash';
import { createIntl, createIntlCache } from 'react-intl';

import {
  STORECODE_ALL_BEATBOX,
  STORECODE_ALL_BEAT,
  STORECODE_ALL,
  STR_INIT_STORE,
  STR_ZONE,
} from 'domains/dashboard/bis/constants/const-strings';
import { ENUM_STORE_TYPE } from 'domains/dashboard/bis/constants/enums';
import AppLocale from 'i18n';
import theme from 'styles/theme';

import { isDate1InThisWeek, isPast } from './date-helper';
import { FormatMsg } from './format-msg';
import { COLOR_DOWN, COLOR_UP } from '../domains/dashboard/bis/constants/color';

const cache = createIntlCache();
const intl = createIntl(AppLocale.en, cache);

/**
 * Zone- 이 포함 된 내용을 찾는다.
 * @param {*} zone
 * @returns
 */
export const CFN_findZone = (zone) => zone.name.startsWith(STR_ZONE);

/**
 *
 * @param {number} value
 * @param {*} locale
 * @returns
 */
// 앞에 0으로 패딩 하고 싶다면 padStart()를 사용 하면 된다.
// ex) export const toCupString = (value) => {return `${value.toLocaleString('kr-KR').padStart(3, '0')}잔`};
export const CFN_addComma = (value, none = '-', locale = 'kr-KR') => {
  if (typeof value !== 'number') return none;
  return value.toLocaleString(locale);
};

/**
 * ALL, ALL_BEAT, ALL_BEATBOX 포함
 * @param {string} storeCode string
 * @returns boolean
 */
export const CFN_isIncludeAllStore = (storeCode) => {
  if (storeCode === STORECODE_ALL || storeCode === STORECODE_ALL_BEAT || storeCode === STORECODE_ALL_BEATBOX) {
    return true;
  }
  return false;
};

/**
 * ALL_BEAT, ALL_BEATBOX만 포함
 * @param {string} storeCode string
 * @returns boolean
 */
export const CFN_isIncludeAllBeatAllBeatbox = (storeCode) => {
  if (storeCode === STORECODE_ALL_BEAT || storeCode === STORECODE_ALL_BEATBOX) {
    return true;
  }
  return false;
};

/**
 *
 * @param {string} storeCode string
 * @param {string} floorId string
 * @returns ENUMS
 */
export const CFN_enumStoreType = (storeCode, floorId = null) => {
  if (storeCode === STORECODE_ALL) {
    return ENUM_STORE_TYPE.ALL_STORE;
  }
  if (storeCode === STORECODE_ALL_BEAT) {
    return ENUM_STORE_TYPE.ALL_BEAT;
  }
  if (storeCode === STORECODE_ALL_BEATBOX) {
    return ENUM_STORE_TYPE.ALL_BEATBOX;
  }
  if (storeCode && floorId !== null) {
    return ENUM_STORE_TYPE.BEATBOX;
  }
  if (storeCode && floorId === null) {
    return ENUM_STORE_TYPE.BEAT;
  }
  return ENUM_STORE_TYPE.UNKNOWN;
};

/**
 * 어드민 여부
 * @param {string} roleName string
 * @returns boolean
 */

export const CFN_isAdmin = (roleName) => roleName === 'ADMIN';

/**
 * 초기 주소 여부
 * @param {string} value string
 * @returns boolean
 */
export const CFN_isInitstore = (value) => value === STR_INIT_STORE;

/**
 * number가 0보다 크거나 작을때의 색상값
 * @param {number} param
 * @returns color string
 */
export const CFN_IncreaseColor = (param) => {
  let color = theme.color.darkGray;

  if (isNaN(param) || param === 0) {
    return color;
  }

  if (param < 0) {
    color = COLOR_DOWN;
  } else if (param > 0) {
    color = COLOR_UP;
  }

  return color;
};

/**
 * 전제 매장 또는 전체 비트박스 매장
 * @param {string} storeCode
 * @param {string} floorId
 * @returns
 */
export const CFN_isAllorAllBeatBox = (storeCode, floorId = null) => {
  const storeType = CFN_enumStoreType(storeCode, floorId);
  return storeType === ENUM_STORE_TYPE.ALL_STORE || storeType === ENUM_STORE_TYPE.ALL_BEATBOX;
};

/**
 * 비트박스 (라이다 o) 인가
 * @param {string} storeCode
 * @param {string} floorId
 * @returns
 */
export const CFN_isBeatBox = (storeCode, floorId = null) => {
  const storeType = CFN_enumStoreType(storeCode, floorId);
  return storeType === ENUM_STORE_TYPE.BEATBOX;
};

/**
 * 날짜의 요일을 얻는다.
 * @param {date} date
 * @returns
 */
export const CFN_getDayOfWeek = (date) => {
  const week = ['일', '월', '화', '수', '목', '금', '토'];

  const dayOfWeek = week[new Date(date).getDay()];
  return dayOfWeek;
};

/**
 * 날짜의 요일을 얻는다.
 * @param {number} index
 * @returns
 */
export const CFN_geyDayOfWeekByIndex = (index) => {
  const week = ['일', '월', '화', '수', '목', '금', '토'];

  if (index > week.length) return 'err';

  return week[index];
};

/**
 * 소수 2자리까지 표현 한 %
 * 입력값이 0 또는 100일 경우는 소주 자리는 표현 하지 않는다.
 * @param {*} value
 * @returns
 */
export const CFN_contrastFixed = (value) => {
  if (typeof value === 'number') {
    let point = 2;
    if (value === 100 || value === 0) {
      point = 0;
    }
    return `${Math.abs(value).toFixed(point)}%`;
  }
  return value;
};

/**
 * 대비 값을 토글 스위치에 따라 문자열로 나타낸다.
 * @param {number} value
 * @param {string} unit
 * @param {boolean} isContrast
 * @returns
 */
export function CFN_contrastValue(value, isContrast = false) {
  if (typeof value !== 'number') {
    if (value === 'NaN' || value === '-') return '-';
    return '';
  }

  const sinPlus = (value) => {
    if (value > 0) return '+';
    if (value < 0) return '-';
    return '';
  };

  return isContrast ? `${sinPlus(value)}${CFN_contrastFixed(value)}` : `${sinPlus(value)}${CFN_addComma(Math.abs(value))}`;
}

/**
 * 값이 소수점일 경우 몇자리까지 표현할 것인지 계산
 * @param {number} value
 * @param {number} point
 * @returns
 */
export const CFN_TOFIXED = (value, point = 2) => {
  if (typeof value !== 'number') {
    if (value === 'NaN' || value === '-') return '-';
    return '';
  }

  let decimalPoints = point;
  if (value === 100 || value === 0) {
    decimalPoints = 0;
  }

  return parseFloat(value.toFixed(decimalPoints));
};

/**
 * 카테고리 비율에서 사용될 이름 반환
 * @param {string} type 카테고리 타입
 * @returns
 */
export const CFN_CategoryConvertName = (type) => {
  if (type === 'C') return 'Coffee';
  if (type === 'NC') return 'Non Coffee';
  if (type === 'H') return 'HOT';
  if (type === 'I') return 'ICE';

  return 'UNKNOWN';
};

/**
 * @param {string} value
 * @returns
 */
export function userCount(value) {
  return `${CFN_addComma(value)}${intl.formatMessage({ id: 'unit.human' })}`;
}

/**
 * V2 카드 레이아웃
 * @param {number} id
 * @param {string} title
 * @param {number} height
 * @param {*} content
 * @param {string} help
 * @param {string} etc
 * @param {*} breakpoints // [xs, sm, md, lg, xl]
 * @returns
 */
export const V2CardLayout = (id, title, height, content, help, etc, breakpoints) => ({
  id,
  title,
  height,
  content,
  help,
  etc,
  breakpoints,
});

/**
 * x축 날짜 노출 개수 계산하기
 * @param {number} diff
 * @returns
 */
export const getChartXaxisExposureNum = (diff) => {
  const scope = Math.ceil(diff / 6);
  return Math.floor(diff / scope);
};

/**
 * y축 최고값 계산하기
 * @param {num} num
 * @returns
 */
export const getChartYaxisMaxValue = (num) => {
  const floor = Math.floor(num);
  const mapfn = (arg) => Number(arg);
  const arr = Array.from(String(floor), mapfn);
  const power = 10 ** Math.floor(Math.log10(floor));
  const firstDigit = Math.floor(floor / power);
  const result = (num, idx, add) => (String(num)[idx] % 2 !== 0 ? num + add : num);

  if (floor < 10) {
    return 10;
  }
  if (arr.length === 4) {
    const newNum = Math.ceil(num * 0.01) * 100;
    return result(newNum, 1, 100);
  }
  if (arr.length === 5) {
    const newNum = Math.ceil(num * 0.001) * 1000;
    return result(newNum, 1, 1000);
  }
  if (arr.length >= 6) {
    const newNum = Math.ceil(num * 0.0001) * 10000;
    const idx = arr.length - 5;
    return result(newNum, idx, 10000);
  }
  return (firstDigit + 1) * power;
};

/**
 * 두 날짜 사이의 일수 구하기
 * @param {object} date
 * @returns
 */
export const CFN_dateDiff = (date) => {
  const start = dayjs(date.startDate);
  const end = dayjs(date.endDate);
  return end.diff(start, 'day');
};

/**
 * 주어진 링크(`link`)에 현재 경로(`pathname`)의 일부가 포함되어 있는지 확인하는 함수
 * `pathname`의 마지막 '/' 이전 부분이 `link`에 포함되어 있는지 여부를 반환
 * @param {string} pathname
 * @param {string} link
 * @returns
 */
export const CFN_includesPath = (pathname, link) => {
  const findPosition = pathname.lastIndexOf('/');
  const pathFirst = pathname.substring(0, findPosition);
  const isinclude = pathFirst.length > 0 ? `${link}`.includes(pathFirst) : false;
  return isinclude;
};

/**
 * 시간 오전, 오후 표시
 * @param {number} time
 * @returns
 */
export const CFN_hour_h = (time) => {
  if (time >= 12) {
    return `${intl.formatMessage({ id: 'unit.pm' }, { hour: time === 12 ? 12 : time - 12 })}`;
  }
  return `${intl.formatMessage({ id: 'unit.am' }, { hour: time })}`;
};

/**
 * 비율값을 기반으로 화살표와 색상을 생성한 후, 결과를 포함하는 HTML 요소를 반환
 * @param {number} value
 * @param {string} trend
 * @returns
 */
export function CFN_ratio(value, notArrow) {
  const getArrow = () => {
    if (notArrow) return '';
    return value > 0 ? '↑' : '↓';
  };

  const getColor = () => {
    if (notArrow) return theme.color.darkGray;
    return value > 0 ? theme.color.red : theme.color.blue;
  };

  const getCheckValue = () => {
    if (notArrow) return value;
    return value < 0 ? Math.abs(value) : value;
  };

  return <span style={{ color: getColor(), fontWeight: 'normal' }}>{`(${getCheckValue()}%)${getArrow()}`}</span>;
}

/**
 * 데이터의 모든 요소가 0인지 확인하고 true면 0 값을 undefined로 바꾼다.
 * @param {string} data
 * @returns
 */
export const CFN_zerosToUndefined = (data) => {
  if (data.filter((num) => num === 0)) {
    return data.map((el) => (el === 0 ? undefined : el));
  }
  return data;
};

/**
 * 주어진 값에 따라 특정 색상을 반환하는 함수
 * 값에 '+'나 '-' 기호가 포함된 경우 해당 기호에 따라 색상이 지정
 * @param {*} value
 * @returns
 */
export const CFN_updateColor = (value) => {
  const str = String(value);
  const checkValue = (includes) => str.length > 1 && str?.includes(includes);

  if (checkValue('+')) {
    return theme.color.blue;
  }
  if (checkValue('-')) {
    return theme.color.red;
  }
  return theme.color.darkGray;
};

/**
 * 주어진 상태 값에 따라 해당 운영 상태룰 반환하는 함수
 * @param {string} status
 * @returns
 */
export const CFN_operatingStatus = (status) => {
  const statusMap = {
    OPEN: 'cards.content.status.open',
    CHECK: 'cards.content.status.check',
    CLOSE: 'cards.content.status.close',
    CLOSED: 'cards.content.status.close',
    ABNORMALITY: 'cards.content.status.abnormality',
    WASHING: 'cards.content.status.washing',
  };
  return statusMap[status] ? FormatMsg(statusMap[status]) : FormatMsg('manage.users.role.unknown');
};

/**
 * 숫자를 변환하여 단위와 함께 반환하는 함수
 * 기본적으로 1000으로 나눈 후 소수점 첫째 자리까지 올림
 *
 * @param {number} num
 * @param {string} unit
 * @returns
 */
export const CFN_convertUnit = (num, unit = '') => {
  const value = Math.ceil((num / 1000) * 10) / 10;
  return unit ? `${value}${unit}` : value;
};

/**
 * 주어진 상태 값에 따라 해당 권한을 반환하는 함수
 * @param {string} role
 * @returns
 */
export const CFN_roleName = (role) => {
  const roleMap = {
    ADMIN: FormatMsg('manage.users.role.admin'),
    MANAGER: FormatMsg('manage.users.role.manager'),
  };

  return roleMap[role];
};

/**
 * 주어진 텍스트가 특정 길이를 초과할 경우, 텍스트를 자르고 줄임표(...)를 추가하여 반환하는 함수
 * @param {string} text
 * @param {number} maxLength
 * @returns
 */
export function CFN_truncateText(text, maxLength) {
  if (!text) return null;
  if (text?.length <= maxLength) {
    return <span>{text}</span>;
  }
  const truncatedText = `${text?.substring(0, maxLength)}...`;
  return <span title={text}>{truncatedText}</span>;
}

/**
 * 주어진 상태 값에 따라 해당 상태 이름을 반환하는 함수
 * @param {string} status
 * @returns
 */
export const CFN_statusName = (status) => {
  const statusMap = {
    WAIT: FormatMsg('manage.users.status.wait'),
    ACTIVE: FormatMsg('manage.users.status.active'),
    STOP: FormatMsg('manage.users.status.stop'),
  };

  return statusMap[status];
};

/**
 * USP 등록 가능한 쿠폰 필터
 * @param {array} data
 * @param {string} date
 */
export const CFN_filterByUspAvailableCoupons = (data, date) => {
  const filtersCoupons = data.filter((item) => {
    const publishStartDate = new Date(item.publish_st_date);
    const publishEndDate = new Date(item.publish_ed_date);
    const targetDate = new Date(date);
    const dayOfWeek = dayjs(targetDate).day();
    const reserveDate = item.reserve_dates.some((item) => dayjs(item).isSame(date, 'day'));

    const daysArray = item.days_of_week.split(',').map((day) => {
      const num = Number(day) + 1;
      return num === 7 ? 0 : num;
    });

    return (
      !reserveDate &&
      daysArray.includes(dayOfWeek) &&
      !isDate1InThisWeek(targetDate) &&
      !isPast(targetDate) &&
      targetDate >= publishStartDate &&
      targetDate <= publishEndDate
    );
  });
  return filtersCoupons;
};

/**
 * 라인 그래프 날짜 노출 개수 계산하기
 * @param {array} arr
 */
export const calculateDateCountForLineChart = (arr) => {
  const count = Math.ceil(arr.length / 6);
  return Math.floor(arr.length / count);
};

/**
 * 배열을 n개의 부분 배열로 나누기
 * @param {array} arr
 * @param {number} num
 * @returns
 */
export const partitionArray = (arr, num, fix) => {
  const middle = arr.slice(1, arr.length - 1);

  const chunkedArray = [];
  const chunkSize = Math.ceil(middle.length / 5);
  let currentIndex = 0;

  for (let i = 0; i < num; i++) {
    const currentChunkSize = i < middle.length % num ? chunkSize - 1 : chunkSize;
    const chunk = middle.slice(currentIndex, currentIndex + currentChunkSize);
    chunkedArray.push(chunk);
    currentIndex += currentChunkSize;
  }

  const result = chunkedArray.map((subArray) => subArray[Math.floor(subArray.length / 2)]);
  const [firstElement] = arr;
  result[0] = firstElement;

  if (fix) {
    result[result.length - 1] = fix;
    result.push(arr[arr.length - 1]);
  } else {
    const [lastElement] = arr.slice(-1);
    result[result.length - 1] = lastElement;
  }
  return result;
};

/*
 * 구독 서비스 체험판 체크하기
 */
export function CFN_isTrialVersion(storeCode, storeList) {
  if (isNull(storeCode)) return null;
  // eslint-disable-next-line react/destructuring-assignment
  const { subscription } = storeList.find((el) => el.code === storeCode);
  const { product } = subscription;
  return product === 'PREMIUM_EX';
}

/**
 * USP 쿠폰 회수율
 */
export const CFN_uspCouponRecoveryRate = (coupon) => {
  const rate = coupon.user_coupon_use_count / coupon.user_coupon_publish_count || 0;
  return `${CFN_TOFIXED(rate * 100, 1) || 0}%`;
};

export const localize = (str) => {
  // const locale = window.sessionStorage.getItem('locale').replace(/"/g, '');
  const locale = 'ko';
  return locale === 'en' ? `${str}_en` : str;
};

export function isPremium(store) {
  if (store?.code === 'ALL' || !store?.subscription) return null;
  const { product } = store.subscription;
  const isServiceAccessible = product !== 'BASIC';
  return isServiceAccessible;
}
