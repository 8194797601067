import React from 'react';

import { List } from '@mui/material';

import NavCollapse from './NavCollapse';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';

function CmtVertical({ menuItems, height }) {
  return (
    <List component="nav" disablePadding sx={{ width: '100%', maxHeight: height, overflow: 'auto' }}>
      {menuItems.map((item, index) => {
        switch (item.type) {
          case 'section':
            // eslint-disable-next-line react/no-array-index-key
            return <NavSection {...item} key={index} />;
          case 'collapse':
            // eslint-disable-next-line react/no-array-index-key
            return <NavCollapse {...item} key={index} />;
          case 'item':
            // eslint-disable-next-line react/no-array-index-key
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      })}
    </List>
  );
}

export default CmtVertical;
