import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import theme from 'styles/theme';

function CmtDialog({ open, setOpen, title, width, disabled, children, handleSubmitClick, type, submit, cancel = 'true' }) {
  const { formatMessage } = useIntl();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledDialog
      open={open}
      fullWidth={!!width}
      width={`${width}px`}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } }}>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <StyledDialogContent>{children}</StyledDialogContent>
      <StyledDialogActions>
        <StyledButton variant="contained" cancel={cancel.toString()} onClick={handleClose}>
          {type === 'confirm' ? formatMessage({ id: 'button.confirm' }) : formatMessage({ id: 'button.cancellation' })}
        </StyledButton>
        {handleSubmitClick && (
          <StyledButton variant="contained" onClick={handleSubmitClick} disabled={disabled}>
            {submit || formatMessage({ id: 'button.register' })}
          </StyledButton>
        )}
      </StyledDialogActions>
    </StyledDialog>
  );
}

export default CmtDialog;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 0px;
    box-shadow: none;
    max-width: ${(props) => props.width};
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding: 24px 26px;
  font-size: 22px;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0px 26px 0px;
  white-space: pre-wrap;
`;

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: center;
  padding: 30px 0px 36px;
`;

const StyledButton = styled(Button)`
  width: 100px;
  height: 40px;
  background-color: ${(props) => (props.cancel === 'true' ? theme.color.softGray : '#000')};
  color: ${(props) => (props.cancel === 'true' ? theme.color.darkGray : '#fff')};
  box-shadow: none;

  &.Mui-selected,
  &.Mui-selected:hover,
  &:hover {
    background-color: ${(props) => (props.cancel ? '#c3c3c3' : '#5a5a5a')};
    box-shadow: none;
  }
`;
