import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { FormatMsg } from './format-msg';

// import 'dayjs/locale/ko';
// dayjs.locale('ko');

const utc = require('dayjs/plugin/utc');

dayjs.extend(duration);
dayjs.extend(utc);

export const formatDate = (date, formatType = 'short', time = '') => {
  // const locale = window.sessionStorage.getItem('locale').replace(/"/g, '');
  const locale = 'ko';
  const formats = {
    short: { ko: 'M/D', en: 'D MMM' },
    long: { ko: `YYYY/M/D${` ${time}`}`, en: `D/M/YYYY${` ${time}`}` },
    full: { ko: 'YYYY/M/D (dd)', en: 'D/M/YYYY (ddd)' },
  };

  return dayjs(date).format(formats[formatType][locale]);
};

/**
 *
 * @param {*} time
 * @param {*} locale
 * @returns
 */
export const formatTime = (time) => {
  // const locale = window.sessionStorage.getItem('locale').replace(/"/g, '');
  const locale = 'ko';
  return dayjs(time).format(locale === 'en' ? 'ha' : 'A h시');
};

/**
 * 현재 날짜를 지정된 형식으로 반환하는 함수
 * @param {string} format
 * @returns
 */
export const getTodayDate = (format = 'DD.MM.YYYY') => dayjs().format(format);

/**
 * 주어진 날짜가 오늘인지 확인하는 함수
 * @param {*} date
 * @returns
 */
export const isToday = (date) => dayjs().isSame(dayjs(date), 'day');

/**
 * Check Is dateTime of Tomorrow
 * @param inputDateTime
 * @returns {boolean}
 */
export const isTomorrow = (inputDateTime) => {
  const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
  return dayjs(inputDateTime).isSame(tomorrow, 'day');
};

/**
 * Check Is dateTime of Yesterday
 * @param inputDateTime
 * @returns {boolean}
 */
export const isYesterday = (inputDateTime) => {
  const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
  return dayjs(inputDateTime).isSame(yesterday, 'day');
};

/**
 *
 * @param {*} start
 * @param {*} value
 * @param {string} unit
 * @param {string} format
 * @returns
 */
export const getCustomAnyDateTime = (start, value = 0, unit = 'days', format = 'YYYY-MM-DD') => {
  const date = dayjs(start);
  if (value === 0) {
    return date.format(format);
  }
  return date.add(value, unit).format(format);
};

/**
 * 주어진 날짜를 지정된 형식으로 변환하여 반환
 * @param {*} date
 * @param {string} format
 * @returns
 */
export const getCustomDateFormat = (date, format = 'YYYY-MM-DD HH:mm:ss') => dayjs(new Date(date)).format(format);

/**
 * 시간 label 리턴
 * @param {*} date
 * @returns 시간 값 리턴
 */
export const timeLabelName = (date) => {
  const tempDate = new Date(date);
  const tmpHour = dayjs(tempDate).format('HH');
  if (tmpHour === '00') {
    return `${dayjs(tempDate).format('M/DD H')}시`;
  }
  return dayjs(tempDate).format('HH');
};

/**
 * 시간 label 리턴
 * @param {*} date
 * @param {string} format default 'HH:mm'
 * @returns
 */
export const timeLabelNameA = (date, format = 'HH:mm') => {
  const tempDate = new Date(date);
  return dayjs(tempDate).format(format);
};

/**
 * 시간 label 리턴
 * @param {*} date
 * @param {string} format default 'HH:mm:ss'
 * @returns
 */
export const timeLabelNameB = (date, format = 'HH:mm:ss') => {
  const tempDate = new Date(date);
  return dayjs(tempDate).format(format);
};

// 지난 기간 대비
// ex) 조회 날짜가 8/2  ~ 8/4 라면
//     지난 기간은 7/30 ~ 8/1 이어야 한다.
export const getCustomPrevPeriodDate = (startdate, enddate) => {
  const tempStartDate = new Date(startdate);
  const tempEndDate = new Date(enddate);

  const diffdays = dayjs(tempEndDate).diff(dayjs(tempStartDate), 'days');
  const tempPrevEndDate = dayjs(tempStartDate).subtract(1, 'days');
  const tempPrevStartDate = dayjs(tempPrevEndDate).subtract(diffdays, 'days');

  const prevPeriod = {
    prevStartDate: formatDate(tempPrevStartDate, 'long'),
    prevEndDate: formatDate(tempPrevEndDate, 'long'),
  };

  return prevPeriod;
};

/**
 * 날짜간 차이 계산
 * @param {*} startdate
 * @param {*} enddate
 * @param {string} unit
 * @returns
 */
export const getDiffdays = (startdate, enddate, unit = 'days') => dayjs(enddate).diff(dayjs(startdate), unit);

/**
 * 날짜 포멧 변경
 * @param {string} date
 * @param {string} format
 * @returns
 */
export const convertFormatDate = (date, format = 'YYYY-MM-DD') => getCustomDateFormat(date, format);

export const DATE_INIT = '1970/01/01';

/**
 * 초를 시간으로 변환
 * mm:ss ~ hh:mm:ss
 * @param {number} secs
 * @returns
 */
export const ConvertSecondstoTime = (secs) => {
  if (secs > 212400) {
    return FormatMsg('const.string.check');
  }
  if (secs < 60) {
    return dayjs.utc(secs * 1000).format('mm:ss');
  }
  return dayjs.duration(secs, 'seconds').format('HH:mm:ss');
};

/**
 * 주어진 날짜를 지정된 로케일에 맞는 형식으로 변환
 * @param {*} date
 * @param {string} locale
 * @returns
 */
export const convertFormatLocalizedDate = (date, locale = 'ko') => dayjs(date).locale(locale).format('YYYY/M/D (ddd)');

/**
 * 주어진 날짜(date)가 현재 주(week) 안에 있는지를 확인
 * @param {*} date
 * @returns
 */
export const isDate1InThisWeek = (date) => {
  const weekStart = dayjs().startOf('week').add(1, 'day');
  const weekEnd = dayjs().endOf('week').add(1, 'day');

  return dayjs(date).isBetween(weekStart, weekEnd, null, '[]');
};

/**
 * 주어진 날짜(date)가 현재 날짜보다 이전인지 확인
 * @param {*} date
 * @returns
 */
export const isPast = (date) => dayjs(date).isBefore(dayjs(), 'day');

/**
 * 주어진 날짜(date)가 속한 월(month)의 첫 주의 시작 날짜를 계산
 * 주어진 날짜(date)의 1일이 ‘일요일‘ 인 경우 전 월의 6일 + 해당 월의 1일
 * @param {*} date
 * @returns
 */
export const getStartOfFirstWeekOfMonth = (date) => {
  const start = dayjs(date).startOf('month').startOf('week');

  return dayjs(date).startOf('month').day() === 0 ? start.subtract(1, 'week') : start;
};

/**
 * 주어진 날짜(date)가 속한 월(month)의 마지막 주의 끝 날짜를 계산
 * @param {*} date
 * @returns
 */
export const getEndOfLastWeekOfMonth = (date) => dayjs(date).endOf('month').endOf('week');

/**
 * 주어진 날짜가 속한 달의 첫 번째 날을 반환
 * @param {*} date
 * @returns
 */
export const getStartOfMonth = (date) => dayjs(date).startOf('month').format('YYYY-MM-DD');

/**
 * 주어진 날짜가 속한 달의 마지막 날을 반환
 * @param {*} date
 * @returns
 */
export const getEndOfMonth = (date) => dayjs(date).endOf('month').format('YYYY-MM-DD');

/**
 *
 * @param {*} date
 * @param {*} locale
 * @param {*} formatType
 * @returns
 */
