import React, { useState } from 'react';

import LayoutContext from './LayoutContext';

function LayoutContextProvider({ children }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        isOpen,
        setOpen,
      }}>
      {children}
    </LayoutContext.Provider>
  );
}

export default LayoutContextProvider;
