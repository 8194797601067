import React, { cloneElement, isValidElement, useCallback, useContext, useEffect, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Collapse, List } from '@mui/material';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import LayoutContext from '@coremat/CmtLayouts/Vertical/LayoutContext/LayoutContext';
import theme from 'styles/theme';
import { CFN_includesPath } from 'utils/comm-function';

import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';

function NavCollapse(props) {
  const location = useLocation();
  const { name, icon, children = [] } = props;
  const { isOpen } = useContext(LayoutContext);
  const isExpandable = children.length;
  const iconStyle = {
    position: 'absolute',
    right: 14,
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#b5b5b5',
    display: !isOpen && 'none',
  };

  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const renderIcon = useCallback(() => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        style: {
          filter:
            active &&
            'brightness(0) saturate(100%) invert(38%) sepia(78%) saturate(1367%) hue-rotate(203deg) brightness(97%) contrast(97%)',
        },
      });
    }
    return null;
  }, [icon, active]);

  useEffect(() => {
    const path = location.pathname;
    const link = children.map((item) => item.link);

    if (link.includes(path)) {
      return setActive(true);
    }
    if (CFN_includesPath(path, link)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location.pathname]);

  const MenuCollapse = (
    <StyledListItem button="true" onClick={handleClick}>
      <StyledListItemButton>
        {/* Display the expand menu if the item has children */}
        {isExpandable && open ? <ArrowDropUpIcon sx={iconStyle} /> : <ArrowDropDownIcon sx={iconStyle} />}
        {/* Display an icon if any */}
        {renderIcon()}
        <StyledNavText active={active} open={isOpen}>
          {name}
        </StyledNavText>
      </StyledListItemButton>
    </StyledListItem>
  );

  const MenuItemChildren = isExpandable ? (
    <StyledCollapse in={open} timeout="auto">
      <List component="div" disablePadding>
        {children.map((item, index) => {
          switch (item.type) {
            case 'section':
              // eslint-disable-next-line react/no-array-index-key
              return <NavSection {...item} key={index} />;
            case 'collapse':
              // eslint-disable-next-line react/no-array-index-key
              return <NavCollapse {...item} key={index} />;
            case 'item':
              // eslint-disable-next-line react/no-array-index-key
              return <NavMenuItem {...item} key={index} />;
            default:
              return null;
          }
        })}
      </List>
    </StyledCollapse>
  ) : null;

  return (
    <div>
      {MenuCollapse}
      {MenuItemChildren}
    </div>
  );
}

export default NavCollapse;

const StyledListItem = styled(List)`
  && {
    padding: 11px 16px;
    display: flex;
    align-items: center;
  }

  &.MuiTouchRipple {
    display: none;
  }

  &:hover,
  &:focus {
    background-color: transparent;
  }

  .Cmt-miniLayout .Cmt-sidebar-content:not(:hover) & {
    padding: 0px;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
`;

const StyledListItemButton = styled.span`
  display: flex;
  align-items: center;
`;

const StyledCollapse = styled(Collapse)`
  && {
    .Cmt-miniLayout .Cmt-sidebar-content:not(:hover) & {
      display: none;
    }
  }
`;

const StyledNavText = styled.span`
  margin-left: 10px;
  font-size: 18px;
  color: ${(props) => (props.active ? theme.color.blue : theme.color.pureGray)};
  white-space: nowrap;

  .Cmt-miniLayout .Cmt-sidebar-content:not(:hover) & {
    display: none;
  }
`;
