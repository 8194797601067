import { STR_LIDAR_TOKEN } from 'domains/dashboard/bis/constants/const-strings';

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

export default getCookie;

export const getCookieLidar = () => getCookie(STR_LIDAR_TOKEN);
