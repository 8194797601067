import React, { lazy, Suspense } from 'react';

import { createBrowserHistory } from 'history';
import { useSelector } from 'react-redux';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import AppWrapper from '@jumbo/components/app-wrapper';
import PageLoader from '@jumbo/components/page-components/PageLoader';
import { STR_INIT_STORE } from 'domains/dashboard/bis/constants/const-strings';
import { URL_REALTIME_SALES, URL_STEP1, URL_STEP2, URL_STEP3 } from 'domains/dashboard/bis/constants/const-urls';
import Error404 from 'domains/dashboard/error';

import ChangePasswordPage from './domains/auth/pages/change-password';

function AppRouter() {
  const history = createBrowserHistory();
  const { authUser } = useSelector(({ auth }) => auth);

  const indexUrl = `/${URL_STEP1}/${URL_REALTIME_SALES}/${STR_INIT_STORE}`;

  const LazyBis = lazy(() => import('./domains/dashboard/bis'));
  const LazyManage = lazy(() => import('./domains/dashboard/manage'));
  const LazyScms = lazy(() => import('./domains/dashboard/scms'));
  const LazyLogin = lazy(() => import('./domains/auth/pages/login'));

  const router = createBrowserRouter([
    {
      path: '/',
      element: <AppWrapper />,
      errorElement: (
        <AppWrapper>
          <Error404 error />
        </AppWrapper>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={indexUrl} replace />,
        },
        {
          path: `/${URL_STEP1}/:menu/:storecode`,
          element: <LazyBis />,
        },
        {
          path: `/${URL_STEP2}/:menu/:storecode`,
          element: <LazyManage />,
        },
        {
          path: `/${URL_STEP3}/:menu/:storecode`,
          element: <LazyScms />,
        },
        {
          path: '/signin',
          element: authUser ? <Navigate to={indexUrl} /> : <LazyLogin />,
        },
        {
          path: '/init/:token',
          element: <ChangePasswordPage />,
        },
        {
          path: '/change/:token',
          element: <ChangePasswordPage />,
        },
      ],
    },
  ]);

  return (
    <Suspense fallback={<PageLoader />}>
      <RouterProvider
        router={router}
        history={history}
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      />
    </Suspense>
  );
}

export default AppRouter;
