import {
  DRAWER_BREAK_POINT,
  HEADER_TYPE,
  LAYOUT_STYLES,
  LAYOUT_TYPES,
  SIDEBAR_TYPE,
  SIDEBAR_WIDTH,
  THEME_TYPES,
} from '@jumbo/constants/theme-options';
import { muiTheme } from 'styles/mui-theme';

const defaultContext = {
  theme: muiTheme,
  defaultLng: {
    languageId: 'korean',
    locale: 'ko',
    name: 'Korea',
    icon: 'ko',
  },
  layout: LAYOUT_TYPES.VERTICAL_MINIMAL, // 현재 사용 중
  layoutType: LAYOUT_STYLES.FULL_WIDTH,
  themeType: THEME_TYPES.LIGHT,
  drawerBreakPoint: DRAWER_BREAK_POINT.MD,
  headerType: HEADER_TYPE.FIXED,
  sidebarType: SIDEBAR_TYPE.FULL,
  isSidebarFixed: true,
  sidebarWidth: SIDEBAR_WIDTH.DEFAULT,
  showFooter: true,
};

export default defaultContext;
