import React, { useContext, useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import AlertContext from 'context/alert-context';
import {
  LOCALSTORAGE_KEY_AUTO_LOGIN,
  SESSION_STORAGE_KEY_LOGIN_STATE,
  STR_INIT_STORE,
} from 'domains/dashboard/bis/constants/const-strings';
import { URL_REALTIME_SALES, URL_STEP1 } from 'domains/dashboard/bis/constants/const-urls';
import useClasses from 'hook/useClasses';
import useSubscription from 'hook/useSubscription';
import { JWTAuth } from 'services';

import VerticalMinimal from './VerticalLayouts/VerticalMinimal';

const styles = {
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function AppLayout({ children }) {
  const { formatMessage } = useIntl();
  const { storecode } = useParams();
  const { alert } = useContext(AlertContext);

  const [showLayoutLoader, setLayoutLoader] = useState(true);

  const authUser = useSelector(({ auth }) => auth.authUser);
  const loadUser = useSelector(({ auth }) => auth.loadUser);
  const { selectedStoreName } = useSelector((state) => state.common);

  const dispatch = useDispatch();
  const classes = useClasses(styles);
  const location = useLocation();
  const storeList = useSelector((state) => state.auth.authUser?.storeList);

  const { hasSubscriptionAccess, isOnSubscriptionPage } = useSubscription();

  const handleAlert = async () => {
    await alert(formatMessage({ id: 'error.message.subscription' }, { name: selectedStoreName }));
  };

  const indexUrl = `/${URL_STEP1}/${URL_REALTIME_SALES}/${STR_INIT_STORE}`;

  useEffect(() => {
    if (authUser && storecode !== 'initstore') {
      const store = storeList?.find((store) => store.code === storecode);
      if (store === undefined) {
        window.location.replace(indexUrl);
      }
    }
  }, [authUser, storecode, storeList]);

  useEffect(() => {
    if (!hasSubscriptionAccess && isOnSubscriptionPage) {
      handleAlert();
    }
  }, [hasSubscriptionAccess, isOnSubscriptionPage]);

  useEffect(() => {
    const isAutoLogin = localStorage.getItem(LOCALSTORAGE_KEY_AUTO_LOGIN);
    const isLogining = sessionStorage.getItem(SESSION_STORAGE_KEY_LOGIN_STATE);

    if (isAutoLogin === 'true') {
      // 자동 로그인을 체크 하고 로그인을 했다면 바로 auth로 이동 시킨다.
      dispatch(JWTAuth.getAuthUser());
    } else if (isLogining === 'true') {
      // 현재 로그인 상태라면 바로 auth로 이동 시킨다.
      dispatch(JWTAuth.getAuthUser());
    } else {
      // 현재 로그인 상태가 아니라면 로그 아웃 시킨다.
      dispatch(JWTAuth.onLogout());
    }
    setLayoutLoader(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showLayoutLoader || !loadUser) {
    return (
      <div className={classes.circularProgressRoot}>
        <CircularProgress />
      </div>
    );
  }

  if (['/signin', '/signup', '/forgot-password'].includes(location.pathname)) {
    return <div style={{ minHeight: '100vh', width: '100%', display: 'flex' }}>{children}</div>;
  }

  // 패스워드 변경/등록
  if (
    location.pathname.substring(0, 5) === '/init' || // 비밀번호 초기 등록 페이지, /init/:token, 문자열 앞을 잘라서 비교
    location.pathname.substring(0, 7) === '/change' // 비밀번호 재 등록 페이지, /change/:token, 문자열 앞을 잘라서 비교
  ) {
    return <div style={{ minHeight: '100vh', width: '100%', display: 'flex' }}>{children}</div>;
  }

  return authUser ? <VerticalMinimal>{children}</VerticalMinimal> : children;
}

export default AppLayout;
