import React from 'react';

import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';

import AlertDialog from 'components/alert/alert-dialog';
import ConfirmDialog from 'components/confirm/confirm-dialog';
import Toast from 'components/toast';
import { globalStyles } from 'styles/global-css';
import { muiTheme } from 'styles/mui-theme';

import AppContextProvider from './@jumbo/components/context-provider/AppContextProvider';
import AppRouter from './app-router';
import { store } from './redux/store';

import 'react-virtualized/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    },
    mutations: {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <Provider store={store}>
          <GlobalStyles styles={globalStyles(muiTheme)} />
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
              <ConfirmDialog>
                <AlertDialog>
                  <Toast />
                  <AppRouter />
                </AlertDialog>
              </ConfirmDialog>
            </ThemeProvider>
          </StyledEngineProvider>
        </Provider>
      </AppContextProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
