import React, { cloneElement, isValidElement, useEffect, useState } from 'react';

import { Box, List, ListItemButton } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { muiTheme } from 'styles/mui-theme';
import { CFN_includesPath } from 'utils/comm-function';

// 접기 메뉴
function NavMenuItem(props) {
  const { name, icon, link, font } = props;
  const location = useLocation();

  const [selected, setSelected] = useState(false);

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        style: {
          filter:
            selected &&
            'brightness(0) saturate(100%) invert(38%) sepia(78%) saturate(1367%) hue-rotate(203deg) brightness(97%) contrast(97%)',
        },
      });
    }
    return null;
  };

  useEffect(() => {
    if (CFN_includesPath(location.pathname, link)) {
      return setSelected(true);
    }
    setSelected(false);
  }, [location.pathname, link]);

  return (
    <StyledList component="div">
      <NavLink to={link}>
        <StyledListItemButton selected={selected} font={font}>
          {/* Display an icon if any */}
          {renderIcon()}
          <StyledBox component="span" font={font}>
            {name}
          </StyledBox>
        </StyledListItemButton>
      </NavLink>
    </StyledList>
  );
}

export default NavMenuItem;

const StyledList = styled(List)`
  && {
    padding: 0px;
    position: relative;

    &.navMenuLinkback {
      .Cmt-miniLayout .Cmt-sidebar-content:not(:hover) & {
        background-color: #fff;
      }
    }
  }
`;

const StyledListItemButton = styled(ListItemButton)`
  && {
    display: flex;
    align-items: center;
    padding: ${(props) => (props.font ? '11px 16px 11px 52px' : '11px 16px')};
    color: ${(props) =>
      props.selected ? `${muiTheme.palette.sidebar.textActiveColor}` : `${muiTheme.palette.sidebar.textColor}`};

    &.Mui-selected,
    &.Mui-selected:hover,
    &:hover {
      background-color: ${muiTheme.palette.sidebar.navHoverBgColor};
    }

    .Cmt-miniLayout .Cmt-sidebar-content:not(:hover) & {
      justify-content: center;
      height: 40px;
      padding: 0px;
      background-color: #fff;
    }
  }
`;

const StyledBox = styled(Box)`
  margin-left: ${(props) => (props.font ? '0px' : '10px')};
  font-size: ${(props) => (props.font ? '16px' : '18px')};
  white-space: nowrap;

  .Cmt-miniLayout .Cmt-sidebar-content:not(:hover) & {
    display: none;
  }
`;
