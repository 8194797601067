import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_STORE_SELECT,
  FETCH_FLOOR_ID_SELECT,
  UPDATE_TIMER_COUNT,
  RESULT_STATUS_PERIOD_SELECT,
  UPDATE_TIMER_HOUR,
  UPDATE_TIMER_MINUTE,
  FETCH_STORENAME_SELECT,
  FETCH_LIDAR_TOKEN,
  UPDATE_SELECTED_MONTH,
  FETCH_URL_MOVE,
  FETCH_PERFORMANCE_PERIOD,
  UPDATE_SELECTED_DATE,
  UPDATE_SELECTED_DATE_RANGE,
  UPDATE_SELECTED_TIME,
  FETCH_PAGE,
  FETCH_LOCALE,
} from '../../@jumbo/constants/action-types';

export const fetchSuccess = (message) => (dispatch) => {
  dispatch({
    type: FETCH_SUCCESS,
    payload: message || '',
  });
};
export const fetchError = (error) => (dispatch) => {
  dispatch({
    type: FETCH_ERROR,
    payload: error,
  });
};

export const fetchStart = () => (dispatch) => {
  dispatch({
    type: FETCH_START,
  });
};

export const storeSelect = (storeCode) => (dispatch) => {
  dispatch({
    type: FETCH_STORE_SELECT,
    payload: storeCode,
  });
};

export const floorIdSelect = (floorId) => (dispatch) => {
  dispatch({
    type: FETCH_FLOOR_ID_SELECT,
    payload: floorId,
  });
};

export const updateTimerCount = (state) => (dispatch) => {
  dispatch({
    type: UPDATE_TIMER_COUNT,
    payload: state,
  });
};

export const resultStatusPeriod = (period) => (dispatch) => {
  dispatch({
    type: RESULT_STATUS_PERIOD_SELECT,
    payload: period,
  });
};

export const updateSelectedDate = (state) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_DATE,
    payload: state,
  });
};

export const updateSelectedDateRange = (period) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_DATE_RANGE,
    payload: period,
  });
};

export const updateSelectedMonth = (period) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_MONTH,
    payload: period,
  });
};

export const updateSelectedTime = (period) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_TIME,
    payload: period,
  });
};

export const updateTimerHour = (state) => (dispatch) => {
  dispatch({
    type: UPDATE_TIMER_HOUR,
    payload: state,
  });
};

export const updateTimerMinute = (state) => (dispatch) => {
  dispatch({
    type: UPDATE_TIMER_MINUTE,
    payload: state,
  });
};

export const storeNameSelect = (storeName) => (dispatch) => {
  dispatch({
    type: FETCH_STORENAME_SELECT,
    payload: storeName,
  });
};

export const storeLidarTokenSelect = (lidarToken) => (dispatch) => {
  dispatch({
    type: FETCH_LIDAR_TOKEN,
    payload: lidarToken,
  });
};

export const fetchUrlMove = (address) => (dispatch) => {
  dispatch({
    type: FETCH_URL_MOVE,
    payload: address,
  });
};

export const fetchPerformancePeriod = (period) => (dispatch) => {
  dispatch({
    type: FETCH_PERFORMANCE_PERIOD,
    payload: period,
  });
};

export const fetchPage = (page) => (dispatch) => {
  dispatch({
    type: FETCH_PAGE,
    payload: page,
  });
};

export const fetchLocale = (locale) => (dispatch) => {
  dispatch({
    type: FETCH_LOCALE,
    payload: locale,
  });
};
