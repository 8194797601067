import { isNull } from 'lodash';

export const STORECODE_ALL = 'ALL';
export const STORECODE_ALL_BEAT = 'ALL_BEAT';
export const STORECODE_ALL_BEATBOX = 'ALL_BEATBOX';
export const STR_ZONE = 'Zone-';
export const STR_DWELL_TIME = 'dwelltime';
export const STR_ECONNABORTED = 'ECONNABORTED';
export const NUMBER_ZERO = 0;
export const NUMBER_MINUS_100 = -100;

export const SESSION_STORAGE_KEY_SELECTED_STORE_CODE = 'selectedStoreCode';
export const SESSION_STORAGE_KEY_PERIOD_RESULT = 'PeriodResult';
export const SESSION_STORAGE_KEY_PERIOD_PERFORMANCE = 'PeriodPerformance';
export const SESSION_STORAGE_KEY_COMPARE_PERIOD_TYPE = 'ComparePeriodType';
export const SESSION_STORAGE_KEY_PERIOD_OP_INDICATOR = 'PeriodOpIndicator';
export const SESSION_STORAGE_KEY_MONTHLY_DATE = 'monthlyDate';
export const SESSION_STORAGE_KEY_LOGIN_STATE = 'loginState';
export const SESSION_STORAGE_KEY_EQUIP_MODE = 'equipMode';
export const SESSION_STORAGE_KEY_EQUIPMENT_OFF = 'equipmentOff';
export const SESSION_STORAGE_KEY_SELECTED_DAY = 'selectedDay';
export const SESSION_STORAGE_KEY_PERIOD_OPERATION = 'PeriodOperation';
export const SESSION_STORAGE_KEY_EQUIPMENT_DATE = 'equipmentDate';
export const SESSION_STORAGE_KEY_EQUIPMENT_BTN_VALUE = 'equipmentBtnValue';
export const SESSION_STORAGE_KEY_SELECTED_SCHEDULE_MONTH = 'selectedScheduleMonth';
export const SESSION_STORAGE_KEY_SELECTED_SCHEDULE_DATE = 'selectedScheduleDate';

export const LOCALSTORAGE_KEY_AUTO_LOGIN = 'autoLogin';

export const STR_DATE = 'date';
export const STR_START_DATE = 'startDate';
export const STR_END_DATE = 'endDate';
export const STR_COMPARE_START_DATE = 'compareStartDate';
export const STR_COMPARE_END_DATE = 'compareEndDate';

export const STR_INIT_STORE = 'initstore';

export const STR_LIDAR_TOKEN = 'lidarToken';

/**
 * v 값의 null, undefined 를 체크 해 본다.
 * @param {any} v
 * @returns
 */
export const isNullorUndefied = (v) => {
  if (isNull(v) || v === 'null' || v === 'undefined') {
    return true;
  }
  return false;
};

/**
 * service에서 사용할 부분인데, useIntl 가 잘 안된다.
 * 추후 방법을 찾아 보자.
 */
export const ERRORCODE_1004_KR = '등록되지 않은 이메일 주소이거나, 잘못된 이메일 주소 또는 비밀번호 입니다.';
export const ERRORCODE_9001_KR = '잘못된 이메일 주소 또는 비밀번호 입니다.';
export const ERRORCODE_9009_KR = '등록된 매장이 없어 로그인할 수 없습니다.';
export const ERROR_LOGIN_DISABLE_KR = '로그인 불가';

export const CONVERT_RATIO_TO_SECONDS = {
  openSecondsRatio: 'openSeconds',
  closedSecondsRatio: 'closedSeconds',
  abnormalitySecondsRatio: 'abnormalitySeconds',
  prevOpenSecondsRatio: 'prevOpenSeconds',
  prevClosedSecondsRatio: 'prevClosedSeconds',
  prevAbnormalitySecondsRatio: 'prevAbnormalitySeconds',
};

export const indicatorStatus = {
  OPEN: '정상',
  CLOSED: '점검중',
  ABNORMALITY: '비정상',
};
