import React, { useState, useEffect, Fragment } from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import CmtDialog from '@coremat/CmtDialog';
import { emailPattern } from 'domains/auth/constants/regex';
import { agree_personalInfo } from 'domains/auth/pages/agree/agree-personalInfo';
import { agree_terms } from 'domains/auth/pages/agree/agree-terms';
import { LOCALSTORAGE_KEY_AUTO_LOGIN } from 'domains/dashboard/bis/constants/const-strings';
import { JWTAuth } from 'services';

import Auth from '.';
import AuthTextField from './input';

function SignIn() {
  const { formatMessage } = useIntl();

  const TermsPolicy = [
    {
      label: 'terms.agree',
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: agree_terms,
          }}
        />
      ),
    },
    {
      label: 'terms.personalInfo',
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: agree_personalInfo,
          }}
        />
      ),
    },
  ];

  const dispatch = useDispatch();

  const [isSaveMemberId, saveMemberId] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validation, setValidation] = useState({ email: true, password: true }); // true면 유효함

  useEffect(() => {
    const isAutoLogin = localStorage.getItem(LOCALSTORAGE_KEY_AUTO_LOGIN);
    if (isAutoLogin) saveMemberId(true);
  }, []);

  const changeEmail = (event) => {
    setEmail(event.target.value);

    const pattern = new RegExp(emailPattern);
    const hasError = !email || !pattern.test(email);

    setValidation({ ...validation, email: !hasError });
  };

  const changePassword = (event) => {
    setPassword(event.target.value);

    // eslint-disable-next-line prefer-regex-literals
    const pattern = new RegExp(/.{8,}/);
    const hasError = !password || !pattern.test(password);

    setValidation({ ...validation, password: !hasError });
  };

  const onChangeCheckboxHandler = (event) => {
    saveMemberId(event.target.checked);
    if (event.target.checked === true) {
      // 자동로그인이 체크되어 있다면, 로컬 스토리지에 변수 저장
      localStorage.setItem(LOCALSTORAGE_KEY_AUTO_LOGIN, true);
    } else {
      localStorage.removeItem(LOCALSTORAGE_KEY_AUTO_LOGIN);
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (!email.trim() || !password.trim()) return;
    if (!Object.values(validation).every((valid) => valid === true)) return;
    dispatch(JWTAuth.onLogin({ email, password }));
  };

  const isNotEmptyString = (value) => value !== '';

  const SignInFormData = [
    {
      id: 'email',
      label: isNotEmptyString(email) ? null : formatMessage({ id: 'appModule.email' }),
      onChange: (e) => changeEmail(e),
      value: email,
      error: isNotEmptyString(email) && !validation.email,
      helperText: isNotEmptyString(email) && !validation.email ? formatMessage({ id: 'appModule.email.help' }) : null,
    },
    {
      id: 'password',
      label: isNotEmptyString(password) ? null : formatMessage({ id: 'appModule.password' }),
      onChange: (e) => changePassword(e),
      value: password,
      error: isNotEmptyString(password) && !validation.password,
      helperText:
        isNotEmptyString(password) && !validation.password ? formatMessage({ id: 'appModule.password.help' }) : null,
    },
  ];

  const isLogin = !isNotEmptyString(email) || !isNotEmptyString(password) || !validation.email || !validation.password;

  const [selectedTerms, setSelectedTerms] = useState(null);

  const handleOpen = (item) => {
    setSelectedTerms(item.label);
  };

  return (
    <Auth onSubmit={submitHandler}>
      <Auth.Title>b;eat corp.</Auth.Title>
      <form onSubmit={submitHandler} style={{ textAlign: 'start' }}>
        {SignInFormData.map((data) => (
          <AuthTextField
            key={data.id}
            id={data.id}
            label={data.label}
            value={data.value}
            onChange={data.onChange}
            error={data.error}
            helperText={data.helperText}
          />
        ))}
        <FormControlLabel
          control={
            <Checkbox
              sx={{ padding: '6px 6px 6px 8px' }}
              size="small"
              name="checkedA"
              onChange={(event) => onChangeCheckboxHandler(event)}
              checked={isSaveMemberId}
            />
          }
          label={<span style={{ fontSize: '14px' }}>{formatMessage({ id: 'appModule.rememberMe' })}</span>}
        />
        <Auth.Submit disabled={isLogin}>{formatMessage({ id: 'appModule.signIn' })}</Auth.Submit>
      </form>
      <Auth.Bottom>
        {TermsPolicy.map((item, idx) => (
          <Fragment key={item.label}>
            <StyledTermsPolicy onClick={() => handleOpen(item)}>{formatMessage({ id: item.label })}</StyledTermsPolicy>
            {idx !== TermsPolicy.length - 1 && <span style={{ margin: '0px 10px', color: '#b5b5b5' }}>|</span>}
            <CmtDialog type="confirm" open={selectedTerms === item.label} setOpen={setSelectedTerms}>
              {item.content}
            </CmtDialog>
          </Fragment>
        ))}
      </Auth.Bottom>
    </Auth>
  );
}

export default SignIn;

const StyledTermsPolicy = styled.span`
  font-size: 13px;
  cursor: pointer;
`;
