import axios from 'axios';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BEATCORP_CPAPI,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  crossDomain: true,
});

export const logistics = axios.create({
  baseURL: process.env.REACT_APP_BEATCORP_LOGISTICS_API,
  timeout: 30000,
  headers: {
    'Content-type': 'application/json',
  },
});
