import React from 'react';

import { Box, Button } from '@mui/material';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import theme from 'styles/theme';

export default function Auth({ children }) {
  const { formatMessage } = useIntl();
  const backgroundImage = '/images/auth/auth-bg-pattern.png';

  return (
    <AuthContainer bgimg={backgroundImage}>
      <StyledAuthContent>
        {children}
        <StyledFooter>{formatMessage({ id: 'footer.copyright' }, { date: new Date().getFullYear() })}</StyledFooter>
      </StyledAuthContent>
    </AuthContainer>
  );
}

Auth.Title = function AuthTitle({ children }) {
  return (
    <StyledAuthHeader>
      <span className="title">{children}</span>
      <span className="cp">CP Page</span>
    </StyledAuthHeader>
  );
};

Auth.Submit = function AuthSubmit({ children, disabled, onClick }) {
  return (
    <Box>
      <StyledSubmitBtn onClick={onClick} type="submit" variant="contained" color="primary" disabled={disabled}>
        {children}
      </StyledSubmitBtn>
    </Box>
  );
};

Auth.Bottom = function AuthBottom({ children }) {
  return children;
};

const AuthContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  width: 100%;
  background-image: ${({ bgimg }) => `URL(${bgimg})`};

  ::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 20, 200, 0.4);
    content: '';
  }
`;

const StyledAuthContent = styled(Box)`
  text-align: center;
  padding: 40px;
  max-width: 620px;
  width: 100%;
  background-color: #fff;
  z-index: 1;
`;

const StyledAuthHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    color: #000;
    font-size: 22px;
    font-weight: bold;
  }

  .cp {
    color: ${theme.color.darkGray};
    font-size: 14px;
  }
`;

const StyledSubmitBtn = styled(Button)`
  margin: 20px 0px;
  width: 100%;
  height: 56px;
  font-size: 16px;
  box-shadow: none;

  &.Mui-selected,
  &.Mui-selected:hover,
  &:hover {
    box-shadow: none;
  }
`;

const StyledFooter = styled.p`
  margin-top: 10px;
  font-size: 12px;
`;
