import React from 'react';

import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

import theme from 'styles/theme';

function Footer(props) {
  const { formatMessage } = useIntl();
  const date = new Date();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" {...props}>
      <Box display="flex" alignItems="center">
        <span style={{ fontSize: 14, color: theme.color.darkGray }}>
          {formatMessage({ id: 'footer.copyright' }, { date: date.getFullYear() })}
        </span>
      </Box>
    </Box>
  );
}

export default Footer;
