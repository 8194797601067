import React from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {
  STR_LIDAR_TOKEN,
  STR_ECONNABORTED,
  SESSION_STORAGE_KEY_SELECTED_STORE_CODE,
  SESSION_STORAGE_KEY_LOGIN_STATE,
  ERRORCODE_9009_KR,
  ERROR_LOGIN_DISABLE_KR,
} from 'domains/dashboard/bis/constants/const-strings';
import { fetchError, fetchStart, fetchSuccess, storeLidarTokenSelect, storeSelect } from 'redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from 'redux/actions/Auth';

import { instance } from './http';

const MySwal = withReactContent(Swal);

const sweetAlerts = (title, text, icon = 'error') => {
  MySwal.fire({
    icon,
    title,
    text,
    allowOutsideClick: false,
  });
};

// 라이다토큰 쿠키 저장
const setLidarCookie = (token) => {
  // const expires = new Date(Date.now() + 1000* 60 * 60 * 24 * 7 )
  const expires = new Date(Date.now() + 6048e5).toUTCString(); // plus 7days
  document.cookie = `${STR_LIDAR_TOKEN}=${token}; path=/; domain=${document.location.hostname}; expires=${expires};`;
};

export const apiCall = (_url, _method, _params = {}, http = instance) => {
  // 디버그용 timeout key
  const debug_timeout = sessionStorage.getItem('debug_timeout');

  return (dispatch) =>
    http
      .request({
        url: _url,
        method: _method,
        params: _method === 'get' ? _params : {},
        data: _method === 'post' ? _params : {},

        // `timeout` specifies the number of milliseconds before the request times out.
        // If the request takes longer than `timeout`, the request will be aborted.
        timeout: debug_timeout || 20000, // ms
      })
      .then(({ data }) => data)
      .catch((error) => {
        // timeout이 발생 하게 되면 많은 error condition이 발생하게 되는데,
        // 간단하게 캐치 하기 위해서 문자열 ECONNABORTED를 리턴하도록 하였다.
        // axio로 받는 쪽에서 해당 문자열을 받았을 경우 적절하게 timeout 처리를 해주면 될 것 같다.
        if (error.code === STR_ECONNABORTED) {
          console.warn(`### [url] : ${error.config.url}, [message] : ${error.message}`);
          return STR_ECONNABORTED;
        }

        if (error.response.data) {
          console.warn(`### [${error.config.url}], [${error.response.data.code}] ${error.response.data.message}`);
        } else {
          console.error(error.message);
        }

        dispatch && dispatch(errorHandle(error)); // status 코드에 따른 공통 에러처리

        throw error;
      });
};

const errorHandle = (error) => (dispatch) => {
  // const cpError = error.response.data
  if (error.response.status === 401 || error.response.status === 403) {
    // 권한 없음 HttpStatus코드일때 로그인화면으로 이동
    // TODO: 리프레시토큰 발급

    sessionStorage.removeItem(SESSION_STORAGE_KEY_SELECTED_STORE_CODE);
    dispatch(setAuthUser(null));
  }
};

export const JWTAuth = {
  // onRegister: ({ name, email, password }) => {
  //   return (dispatch) => {
  //     dispatch(fetchStart());
  //     axios
  //       .post('auth/register', {
  //         email: email,
  //         password: password,
  //         name: name,
  //       })
  //       .then(({ data }) => {
  //         if (data) {
  //           axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken;
  //           dispatch(fetchSuccess());
  //           dispatch(JWTAuth.getAuthUser(true));
  //           setLidarCookie(data.lidarToken);
  //           dispatch(storeLidarTokenSelect(data.lidarToken));
  //         } else {
  //           dispatch(fetchError(data.error));
  //         }
  //       })
  //       .catch(function (error) {
  //         dispatch(fetchError(error.message));
  //       });
  //   };
  // },

  onLogin: ({ email, password }) => {
    delete instance.defaults.headers.common.Authorization;

    return (dispatch) => {
      try {
        dispatch(fetchStart());
        instance
          .post('auth/login', {
            memberId: email,
            password,
          })
          .then(({ data }) => {
            if (data) {
              dispatch(fetchSuccess());
              setLidarCookie(data.lidarToken);
              dispatch(storeLidarTokenSelect(data.lidarToken));
              sessionStorage.setItem(SESSION_STORAGE_KEY_LOGIN_STATE, 'true');
              dispatch(JWTAuth.getAuthUser(true));
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch((error) => {
            if (error.response.data.code === 1004) {
              dispatch(fetchError());
              sweetAlerts(ERROR_LOGIN_DISABLE_KR, error.response.data.message, 'warning');
            } else if (error.response.data.code === 9001) {
              dispatch(fetchError());
              sweetAlerts(ERROR_LOGIN_DISABLE_KR, error.response.data.message, 'warning');
            } else if (error.response.data.code === 9009) {
              dispatch(fetchError());
              sweetAlerts(ERROR_LOGIN_DISABLE_KR, ERRORCODE_9009_KR, 'error');
            } else {
              dispatch(fetchError(error.message));
            }
          });
      } catch (error) {
        console.log(error);
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => (dispatch) => {
    dispatch(fetchStart());
    instance
      .post('auth/logout')
      .then(({ data }) => {
        // if (data) {
        dispatch(fetchSuccess());
        sessionStorage.clear();
        dispatch(setAuthUser(null));
        dispatch(storeSelect(null));
        // } else {
        //   dispatch(fetchError(data.error));
        // }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  },

  getAuthUser:
    (loaded = false) =>
    (dispatch) => {
      // if (!token) {
      //   const token = localStorage.getItem('token');
      //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      // }

      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      instance
        .get('member')
        .then(({ data }) => {
          if (data) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch((error) => {
          dispatch(updateLoadUser(true));
        });
    },

  onForgotPassword: () => (dispatch) => {
    dispatch(fetchStart());

    setTimeout(() => {
      dispatch(setForgetPassMailSent(true));
      dispatch(fetchSuccess());
    }, 300);
  },

  getSocialMediaIcons: () => <> </>,
};
