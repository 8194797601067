import React, { useState } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import styled from 'styled-components';

function AuthTextField({ id, label, onChange, value, error, helperText }) {
  const isPassword = id.includes('password') || id.includes('Password');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getInputType = () => {
    if (!isPassword) return 'text';
    return showPassword ? 'text' : 'password';
  };

  const getFont = () => {
    if (!isPassword) return 'NanumSquareOTF';
    return showPassword ? 'NanumSquareOTF' : 'sans-serif';
  };

  return (
    <StyledTextField
      fullWidth
      label={label}
      type={getInputType()}
      onFocus={onChange}
      onChange={onChange}
      onKeyUp={onChange}
      value={value}
      margin="normal"
      variant="outlined"
      error={error}
      autoComplete={isPassword ? 'off' : 'on'}
      helperText={helperText}
      font={getFont()}
      InputProps={{
        endAdornment: isPassword ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
}

export default AuthTextField;

const StyledTextField = styled(TextField)`
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font: message-box;
  }
  :first-child {
    margin: 16px 0px 0px;
  }

  [type='password'] {
    font-family: 'Malgun gothic', dotum, sans-serif;
  }
`;
